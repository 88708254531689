import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ConfigurationIcon } from '../../../common/assets/images/configuration-icon.svg'
import { navigateToPath } from '../../../common/Navigation'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import { gray } from '../../../ui/utils/_colors'
import { PersonUtils } from '../../../utils/person.utils'
import PhoneUtils from '../../../utils/phone.utils'

export interface OperatorHeaderProps {
  operatorName?: string
  operatorPhoneNumber?: string
  storeName?: string
}

const OperatorHeader: React.FC<OperatorHeaderProps> = props => {
  const { operatorName, operatorPhoneNumber, storeName } = props

  const handleGoOperatorConfiguration = () => {
    navigateToPath(PagesPathEnum.OPERATOR_CONFIGURATION)
  }

  return (
    <MainContainer>
      <RowContainer>
        <OperatorInfoContainer>
          <Title>Olá, {PersonUtils.getFirstName(operatorName)}.</Title>
          <OperatorInfo color={gray.scale300}>{PhoneUtils.maskPhone(operatorPhoneNumber)}</OperatorInfo>
          <OperatorInfo color={gray.scale400}>{storeName}</OperatorInfo>
        </OperatorInfoContainer>

        <OperatorConfigButton onClick={handleGoOperatorConfiguration}>
          <ConfigurationIcon />
        </OperatorConfigButton>
      </RowContainer>
    </MainContainer>
  )
}

export default OperatorHeader

interface OperatorInfoProps {
  color: string
}

const MainContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: 680px;
  background: ${gray.grayishBlue};
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const OperatorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  margin: 16px 24px 16px 16px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: white;
`

const OperatorInfo = styled.text`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: ${(props: OperatorInfoProps) => props.color};
  letter-spacing: 0.001em;
  padding-top: 4px;
`

const OperatorConfigButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 58px;
  height: 40px;
  margin: 14px;

  border-radius: 100%;

  cursor: pointer;

  &:hover {
    svg rect {
      fill: ${gray.scale200};
    }
  }
`
