import React from 'react'
import styled from 'styled-components'

interface Props {
  icon: React.ReactNode
  text: string
  style?: React.CSSProperties
}

const BiometryInstructionItem = ({ icon, text, style }: Props) => (
  <Container style={style}>
    {icon} <Text>{text}</Text>
  </Container>
)

export default BiometryInstructionItem

const Container = styled.div``

const Text = styled.span`
  margin-left: 3vw;
`
