import { Icon } from '@blueprintjs/core'
import { Box } from 'grommet'
import React, { useState } from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../../../cash-register/common/formatBRL'
import analyticsService from '../../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../../services/analytics/events'
import { EvaluationSimulation } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { gray, green, white } from '../../../../ui/utils/_colors'

interface SimulationProps {
  simulation: EvaluationSimulation[]
}

const InstallmentsSimulationTable: React.FC<SimulationProps> = ({ simulation }) => {
  const [showSimulation, setShowSimulation] = useState<boolean>(false)

  const handleShowSimulationClick = () => {
    analyticsService.sendEvent(showSimulation ? AnalyticsEvents.sp_hide_installments_options : AnalyticsEvents.sp_show_installments_options)
    setShowSimulation(!showSimulation)
  }

  return (
    <MainContainer>
      <Title onClick={handleShowSimulationClick}>
        Visualizar opções de parcelamento
        <ShowSimulationIcon icon={showSimulation ? 'chevron-up' : 'chevron-down'} size={20} />
      </Title>

      {showSimulation && (
        <TableWrapper>
          {simulation.map((installment, id) => (
            <TableRow key={id}>
              <TableData width="30%">
                <InstallmentsText>{installment.numberOfInstallments} parcelas de </InstallmentsText>
              </TableData>
              <TableData width="70%">
                <InstallmentsValue>{formatBRL(installment.installmentAmount)}</InstallmentsValue>
              </TableData>
            </TableRow>
          ))}
        </TableWrapper>
      )}
    </MainContainer>
  )
}

export default InstallmentsSimulationTable

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 24px;
`

const Title = styled.text`
  font-family: Inter;
  color: ${green.dark2};
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  line-height: 20px;
  background-color: ${white.primary};

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 770px) {
    text-align: start;
    inline-size: none;
  }
`

const ShowSimulationIcon = styled(Icon)`
  margin-left: 8px;
`

const TableWrapper = styled.div`
  width: 100%;
  margin: 10px 0px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
`

const TableData = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: start;
`

const InstallmentsText = styled.text`
  color: ${gray.slate};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const InstallmentsValue = styled.text`
  color: ${gray.slate};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`
