import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'
import ApplicationSliceReducer from './application/application.reducer'
import applicationSaga from './application/application.saga'
import BiometrySliceReducer from './biometry/biometry.reducer'
import biometrySaga from './biometry/biometry.saga'
import BudgetSliceReducer from './budget/budget.reducer'
import budgetSaga from './budget/budget.saga'
import ConfigurationsSliceReducer from './configurations/configurations.reducer'
import configurationsSaga from './configurations/configurations.saga'
import InvoicesSliceReducer from './invoice/invoices.reducer'
import invoicesSaga from './invoice/invoices.saga'
import OperatorServiceCenterSliceReducer from './operator-service-center/operator-service-center.reducer'
import operatorServiceCenterSaga from './operator-service-center/operator-service-center.saga'
import operatorSliceReducer from './operator/operator.reducer'
import operatorSaga from './operator/operator.saga'
import TransactionalCreditSliceReducer from './tx-credit/tx-credit.reducer'
import transactionalCreditSaga from './tx-credit/tx-credit.saga'
import BiometryLivenessReducer from './biometry/biometry-liveness.reducer'
import biometryLivenessSaga from './biometry/biometry-liveness.saga'

export const Reducers = combineReducers({
  application: ApplicationSliceReducer.reducer,
  biometry: BiometrySliceReducer.reducer,
  budget: BudgetSliceReducer.reducer,
  configurations: ConfigurationsSliceReducer.reducer,
  operatorServiceCenter: OperatorServiceCenterSliceReducer.reducer,
  transactionalCredit: TransactionalCreditSliceReducer.reducer,
  invoices: InvoicesSliceReducer.reducer,
  operator: operatorSliceReducer.reducer,
  biometryLiveness: BiometryLivenessReducer.reducer,
})

export function* rootSaga() {
  yield all([
    ...applicationSaga,
    ...biometrySaga,
    ...budgetSaga,
    ...configurationsSaga,
    ...operatorServiceCenterSaga,
    ...transactionalCreditSaga,
    ...invoicesSaga,
    ...operatorSaga,
    ...biometryLivenessSaga,
  ])
}

export type ReduxState = ReturnType<typeof Reducers>
