import React from 'react'
import styled from 'styled-components'

interface InfoRowProps {
  color: string
  title: string
  value: string
  subtitle: string
}

const InfoRow: React.FC<InfoRowProps> = ({ color, title, value, subtitle }) => {
  return (
    <MainContainer>
      <CircleContainer>
        <Circle color={color} />
      </CircleContainer>

      <RowContainer>
        <ValueContainer>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </ValueContainer>

        <Subtitle>{subtitle}</Subtitle>
      </RowContainer>
    </MainContainer>
  )
}

export default InfoRow

interface InfoRowStyleProps {
  color: string
}

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 16px;
`

const RowContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

const CircleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const Circle = styled.div<InfoRowStyleProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: InfoRowStyleProps) => props.color};
  margin-right: 16px;
`

const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
`

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
  margin-left: auto;
`

const Subtitle = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  margin-top: 4px;
`
