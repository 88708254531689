import React, { useEffect, useState } from 'react'
import { toaster } from '../../App'
import { Dropdown } from '../../atoms/Dropdown/Dropdown'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { bffParceirosApiService } from '../../services/bff'
import { StoreDto, StoreStatus } from '../../services/bff/retailers/dto/Store.dto'
import LoggedUserSaverService from '../LoggedUserInfoCard/LoggedUserSaverService'

interface StoreSelectionProps {
  handleSelectedStore?: (store?: StoreDto) => void
  updatingStoreLoadingText?: string
}

export const StoreSelection: React.FC<StoreSelectionProps> = ({ handleSelectedStore, updatingStoreLoadingText }) => {
  const [stores, setStores] = useState<Map<string, StoreDto> | undefined>(undefined)
  const [selectedStore, setSelectedStore] = useState<StoreDto | undefined>(undefined)
  const [isUpdatingStore, setIsUpdatingStore] = useState<boolean>(false)

  useEffect(() => {
    const fetchStoresData = async () => {
      const currentStore = bffParceirosApiService.coordinator.getStore()
      setSelectedStore(currentStore)

      const stores = new Map<string, StoreDto>(
        (await bffParceirosApiService.retailers.fetchStores({ status: StoreStatus.ACTIVE })).stores
          .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
          .map(store => [store.id, store])
      )

      setStores(stores)
    }

    fetchStoresData()
  }, [])

  const getStoreSettings = async (storeId?: string) => {
    const storeWithSettings = storeId ? await bffParceirosApiService.retailers.fetchStoreById(storeId) : undefined
    return { settings: storeWithSettings?.settings }
  }

  const onStoreChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStoreId = event.target.value

    if (!selectedStoreId) {
      toaster.showErrorToast('Selecione uma loja')
      return
    }

    setIsUpdatingStore(true)

    const selectedStore = Array.from(stores!.values()).find(store => store.id === selectedStoreId)

    const { settings } = await getStoreSettings(selectedStore!.id)

    bffParceirosApiService.coordinator.setStore({ ...selectedStore!, settings })

    await LoggedUserSaverService.fetchAndSaveUser()

    setSelectedStore(bffParceirosApiService.coordinator.getStore())

    setIsUpdatingStore(false)

    if (handleSelectedStore) handleSelectedStore(selectedStore)
  }

  if (!stores) return <LoadingSpinner />
  if (isUpdatingStore) return <LoadingSpinner text={updatingStoreLoadingText} />

  return (
    <div>
      <Dropdown value={selectedStore?.name} onChange={onStoreChange} options={Array.from(stores!.values())} />
    </div>
  )
}
