import { ImageLabel } from '@scudraservicos/coordinator-client/dist/src/services/biometry/interfaces/biometry.types'
import { Box } from 'grommet'
import { FormPrevious } from 'grommet-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../../App'
import { tablet } from '../../../common/assets/utils/_breakpoints'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import { ApplicationVersionEnum } from '../../../redux/reducers/application/application.types'
import { DOCUMENT_BACK_OPTIONS, DOCUMENT_FRONT_OPTIONS, ImageLabelsText } from '../../../redux/reducers/biometry/biometry.constants'
import BiometrySliceReducer from '../../../redux/reducers/biometry/biometry.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import ApplicationLayout from '../ApplicationLayout'
import { BackDocumentView } from '../Biometry/BackDocumentView'
import BiometryDocumentPickerModal from '../Biometry/BiometryDocumentPickerModal'
import BiometryUploadModal from '../Biometry/BiometryUploadModal'
import CameraView from '../Biometry/CameraView'
import { FrontDocumentView } from '../Biometry/FrontDocumentView'
import { AuthorizationHelper } from '../Biometry/helpers/verify-operator.helper'
import { InvalidImageView } from '../Biometry/InvalidImageView'
import { PreviousContainer, PreviousTitle } from '../Biometry/style'

const ApplicationDocumentStep = () => {
  const dispatch = useDispatch()
  const {
    documentImages,
    selectedDocumentIndex,
    isLoadingDocument,
    isDocumentAuthenticated,
    isLoadingBiometry,
    imageError,
    isCameraOpen,
    isPhoneVerified,
    hasPhoneVerification,
    enableHighRecurrence,
    applicationVersion,
    submitBiometry,
  } = useTypedSelector(state => ({
    isCameraOpen: state.biometry.isCameraOpen,
    isLoadingBiometry: state.biometry.isLoadingBiometry,
    isDocumentAuthenticated: state.biometry.isDocumentAuthenticated,
    isLoadingDocument: state.biometry.isLoadingDocument,
    documentImages: state.biometry.documentImages,
    selectedDocumentIndex: state.biometry.selectedDocumentIndex,
    imageError: state.biometry.imageError,
    isPhoneVerified: state.application.isPhoneVerified,
    hasPhoneVerification: state.configurations.configurations.application.hasPhoneVerification,
    enableHighRecurrence: state.application.borrower?.enableHighRecurrence,
    applicationVersion: state.configurations.configurations.application.version,
    submitBiometry: state.configurations.configurations.application.submitBiometry,
  }))

  const [currentImageType, setCurrentImageType] = useState<'DOCUMENT_FRONT' | 'DOCUMENT_BACK'>('DOCUMENT_FRONT')
  const [selectedDropDownDocument, setSelectedDropDownDocument] = useState<number>(selectedDocumentIndex)
  const [isPickDocumentModalVisible, setIsPickDocumentModalVisible] = useState<boolean>(false)
  const [cameraTitle, setCameraTitle] = useState<string>(ImageLabelsText[ImageLabel.PORTRAIT])
  const [uploadModalState, setUploadModalVisible] = useState<{
    type: 'DOCUMENT_FRONT' | 'DOCUMENT_BACK'
    isVisible: boolean
  }>({
    type: 'DOCUMENT_FRONT',
    isVisible: false,
  })

  // Side Effects: Component Did Mount
  useEffect(() => {
    dispatch(BiometrySliceReducer.actions.resetState())
    dispatch(BiometrySliceReducer.actions.fetchDocument())
  }, [dispatch])

  useEffect(() => {
    setUploadModalVisible({ ...uploadModalState, isVisible: false })
  }, [documentImages])

  const handleOpenDocumentFrontCamera = () => {
    setIsPickDocumentModalVisible(false)
    dispatch(BiometrySliceReducer.actions.selectDocument(selectedDropDownDocument))

    dispatch(BiometrySliceReducer.actions.onCameraEvent(true))
    setCurrentImageType('DOCUMENT_FRONT')
    let imageLabel = DOCUMENT_FRONT_OPTIONS[selectedDropDownDocument].type
    setCameraTitle(ImageLabelsText[imageLabel])
  }

  const handleOpenDocumentBackCamera = () => {
    dispatch(BiometrySliceReducer.actions.onCameraEvent(true))
    setCurrentImageType('DOCUMENT_BACK')
    let imageLabel = DOCUMENT_BACK_OPTIONS[selectedDocumentIndex].type
    setCameraTitle(ImageLabelsText[imageLabel])
  }

  const handleCloseCamera = () => {
    if (documentImages) {
      let selectedDocumentIndex = DOCUMENT_FRONT_OPTIONS.findIndex(doc => doc.type === documentImages[0].documentType)
      if (selectedDocumentIndex === -1) {
        selectedDocumentIndex = DOCUMENT_BACK_OPTIONS.findIndex(doc => doc.type === documentImages[0].documentType)
      }
      setSelectedDropDownDocument(selectedDocumentIndex)
      dispatch(BiometrySliceReducer.actions.selectDocument(selectedDocumentIndex))
    }
    dispatch(BiometrySliceReducer.actions.onCameraEvent(false))
  }

  const handleCaptureImage = (imageBase64: string) => {
    dispatch(BiometrySliceReducer.actions.insertDocument({ imageBase64, documentType: currentImageType }))
  }

  const handleUploadImage = (imageBase64: string) => {
    const { type } = uploadModalState

    if (type === 'DOCUMENT_FRONT') {
      dispatch(BiometrySliceReducer.actions.selectDocument(selectedDropDownDocument))
    }
    dispatch(BiometrySliceReducer.actions.insertDocument({ imageBase64, documentType: type }))
  }

  const handleDocumentSelected = (index?: number) => {
    if (index === null || index === undefined) {
      return
    }

    setSelectedDropDownDocument(index)
  }

  const handlePreviousOnClick = () => {
    dispatch(BiometrySliceReducer.actions.clearImageErrors())
  }

  const handleNextPage = () => {
    if (hasPhoneVerification && !isPhoneVerified) {
      toaster.showErrorToast('Confirme o telefone no ícone no canto superior direito.')
      return
    }

    // Start Application process and reset biometry state
    if (applicationVersion === ApplicationVersionEnum.V2) {
      dispatch(ApplicationSliceReducer.actions.startApplication())
    } else if (applicationVersion === ApplicationVersionEnum.V1 || applicationVersion === ApplicationVersionEnum.V3) {
      if (enableHighRecurrence) {
        dispatch(ApplicationSliceReducer.actions.nextPage())
      } else {
        dispatch(ApplicationSliceReducer.actions.startApplication())
      }
    }
  }

  const handleNewDocumentClick = () => {
    setSelectedDropDownDocument(selectedDocumentIndex)
    setIsPickDocumentModalVisible(true)
  }

  const handleCancelDocumentPickerModalClick = () => {
    setIsPickDocumentModalVisible(false)
  }

  const handleOpenUploadDocumentFrontModal = () => {
    setUploadModalVisible({ type: 'DOCUMENT_FRONT', isVisible: true })
  }

  const handleOpenUploadDocumentBackModal = () => {
    setUploadModalVisible({ type: 'DOCUMENT_BACK', isVisible: true })
  }

  const handleCloseUploadModal = () => {
    setUploadModalVisible({ ...uploadModalState, isVisible: false })
  }

  if (imageError) {
    return (
      <Container>
        <PreviousContainer onClick={handlePreviousOnClick}>
          <FormPrevious />
          <PreviousTitle>Voltar</PreviousTitle>
        </PreviousContainer>

        <InvalidImageView imageError={imageError} previous={handlePreviousOnClick} />
      </Container>
    )
  }

  if (isCameraOpen) {
    // TODO - Instead of using Container, use ApplicationLayout
    return (
      <Container>
        <Box direction="column" gap="medium">
          <PreviousContainer onClick={() => (isLoadingBiometry || isLoadingDocument ? () => {} : handleCloseCamera())}>
            <FormPrevious />
            <PreviousTitle>Voltar</PreviousTitle>
          </PreviousContainer>
          <TitleBox>{cameraTitle}</TitleBox>
        </Box>

        <CameraView onCloseCamera={handleCloseCamera} onCapture={handleCaptureImage} isLoading={isLoadingBiometry || isLoadingDocument} />
      </Container>
    )
  }

  const isNextButtonEnabled = isDocumentAuthenticated || !!(documentImages?.length === 2 && !submitBiometry)

  return (
    <ApplicationLayout subtitle="" title="Biometria" isNextButtonEnabled={isNextButtonEnabled} overrideNextPage={handleNextPage}>
      {/* File upload modal */}
      {AuthorizationHelper.isOperatorAllowedToUploadImage() && (
        <BiometryUploadModal
          handleImage={handleUploadImage}
          isLoading={isLoadingDocument}
          type={uploadModalState.type}
          isVisible={uploadModalState.isVisible}
          onCancel={handleCloseUploadModal}
          onDocumentTypeChange={handleDocumentSelected}
          documentIndex={selectedDropDownDocument}
        />
      )}

      {/* Modal to select document type before taking photo with camera */}
      <BiometryDocumentPickerModal
        isVisible={isPickDocumentModalVisible}
        onCancel={handleCancelDocumentPickerModalClick}
        onDocumentTypeChange={handleDocumentSelected}
        documentIndex={selectedDropDownDocument}
        onOk={handleOpenDocumentFrontCamera}
      />

      <FrontDocumentView handleOpenDocumentCamera={handleNewDocumentClick} handleOpenUploadDocumentModal={handleOpenUploadDocumentFrontModal} />

      <BackDocumentView handleOpenDocumentCamera={handleOpenDocumentBackCamera} handleOpenUploadDocumentModal={handleOpenUploadDocumentBackModal} />
    </ApplicationLayout>
  )
}

export default ApplicationDocumentStep

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 93%;

  padding: 24px;

  max-width: 400px;

  @media (min-width: ${tablet}) {
    margin: auto;
  }
`
const TitleBox = styled.div`
  width: 100%;
  letter-spacing: 0;
  font-family: Roboto;
  font-size: 22px;
  text-align: center;
`
