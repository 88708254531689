import React from 'react'
import styled from 'styled-components'

interface DropdownProps {
  value?: string | number
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: any[]
  placeholder?: string
  successMessage?: string
}

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const { value, onChange, options } = props

  return (
    <SelectContainer>
      <StyledSelect value={value} onChange={onChange}>
        <option value={0}>{value}</option>

        {Array.from(options.values()).map((item, idx) => (
          <option value={item.id} key={idx}>
            {item.name}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  )
}

const SelectContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`
