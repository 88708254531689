import React, { useEffect } from 'react'
import { ReactComponent as BlockIcon } from '../../../../common/assets/images/block-icon.svg'
import analyticsService from '../../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../../services/analytics/events'
import { green, white } from '../../../../ui/utils/_colors'
import TransactionalCreditResultGenericScreen from '../TransactionalCreditResultGenericScreen.tsx'

interface Props {
  handleGoBackToStart?: () => void
}

export const TransactionalCreditApplicationDeniedResult: React.FC<Props> = (props: Props) => {
  const { handleGoBackToStart } = props

  useEffect(() => {
    analyticsService.sendEvent(AnalyticsEvents.sp_denied)
  }, [])

  return (
    <TransactionalCreditResultGenericScreen
      style={{
        mainContainerPadding: '24px 24px 320px 24px',
      }}
      shouldRenderCloseButton
      icon={<BlockIcon />}
      title={'Cliente não foi aprovado'}
      subtitle={'Infelizmente não conseguimos ceder crédito para o cliente neste momento.'}
      renderProgressBar={false}
      onCloseButtonClick={handleGoBackToStart}
      footerButton={{ text: 'Voltar para o Início', textColor: green.dark2, backgroundColor: white.primary, border: `1px solid ${green.dark2}`, onClick: handleGoBackToStart }}
    />
  )
}
