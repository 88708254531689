import { createTheme, ThemeProvider } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { DocumentErrors, StoreErrors, UnmappedErrorMessages } from '../../common/ErrorStrings'
import { validate_cpf } from '../../common/Validators'
import TransactionalCreditForm from '../../organisms/TransactionalCredit/TransactionalCreditForm'
import TransactionalCreditResult from '../../organisms/TransactionalCredit/TransactionalCreditResult'
import { bffParceirosApiService } from '../../services/bff'
import { CreateTransactionalCreditEvaluationReq } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { green } from '../../ui/utils/_colors'
import OperatorsHome from '../Operators/OperatorsHome'
import TransactionalCreditHelper from './TransactionalCreditHelper'
import { TransactionalCreditPages } from './TransactionalCreditPages'
import BudgetReducer from '../../redux/reducers/budget/budget.reducer'

const theme = createTheme({
  palette: {
    primary: {
      main: green.primary300,
    },
  },
})

const TransactionalCredit: React.FC = () => {
  const [page, setPage] = useState<TransactionalCreditPages>(TransactionalCreditPages.Document)
  const [formDocument, setFormDocument] = useState<string>('')
  const [isRunningPreChecks, setIsRunningPreChecks] = useState<boolean>(false)

  const dispatch = useDispatch()

  const { error, isLoading, mutate, data } = bffParceirosApiService.transactionalCredit.useCreateTransactionalCreditEvaluation()

  React.useEffect(() => {
    if (!!data) setPage(TransactionalCreditPages.Result)
  }, [data])

  React.useEffect(() => {
    if (!!error) alert(error.message)
  }, [error])

  const handleRedo = () => {
    dispatch(BudgetReducer.actions.resetRequest())
    setFormDocument('')
    setPage(TransactionalCreditPages.Document)
  }

  const handleConfirmDocument = (document: string, storeId?: string) => {
    if (!storeId) {
      return toaster.showErrorToast(StoreErrors.STORE_CONFIGURATION_REQUIRED)
    }

    if (!validate_cpf(document)) {
      return toaster.showErrorToast(DocumentErrors.INVALID_DOCUMENT)
    }

    setFormDocument(document)
    setPage(TransactionalCreditPages.Form)
  }

  const handleTxCreditEvaluationCreation = async (request: CreateTransactionalCreditEvaluationReq) => {
    if (!request.storeId) {
      return
    }

    setIsRunningPreChecks(true)
    await TransactionalCreditHelper.runPreChecks(request.document, request.storeId).catch(() => undefined)
    mutate(request)
    setIsRunningPreChecks(false)
  }

  // TODO(etevaldo.melo): remove this piece of code once the budget flow
  // using the legacy endpoint.
  if (page == TransactionalCreditPages.Result && !data?.transactionalCreditEvaluation) {
    alert(UnmappedErrorMessages.UNEXPECTED_ERROR)
    setPage(TransactionalCreditPages.Document)
  }

  const renderPage = () => {
    switch (page) {
      case TransactionalCreditPages.Document:
        return <OperatorsHome onConfirmDocument={handleConfirmDocument} />
      case TransactionalCreditPages.Form:
        return <TransactionalCreditForm document={formDocument} onConfirmValue={handleTxCreditEvaluationCreation} onRedo={handleRedo} />
      case TransactionalCreditPages.Result:
        return (
            <TransactionalCreditResult evaluationResult={data?.transactionalCreditEvaluation!!} onRedo={handleRedo}/>
        )
    }
  }

  return (
    <Container>
      <ThemeProvider theme={theme}>{renderPage()}</ThemeProvider>
      {(isLoading || isRunningPreChecks) && <LoadingSpinner text={isRunningPreChecks ? 'Aguarde, essa avaliação pode demorar um pouco. Permaneça na tela...' : 'Analisando proposta...'} />}
    </Container>
  )
}

export default TransactionalCredit

const Container = styled(PhoneLayout).attrs({
  style: {
    padding: 0,
  },
})``
