export enum LocalStorageItem {
  LOGGED_USER = 'logged_user',
  STORE = 'store',
  LOCAL_CASHIER_ID = 'local_cashier_id',
  CAPTURE_METHOD_CONFIG = 'capture_method_config',
  ID_TOKEN = 'id_token',
  USER = 'user',
  CONFIGURATIONS = 'configurations',
  OPERATOR = 'operator',
}

export default class LocalStorageWrapper {
  static setItem(itemName: LocalStorageItem, value: any) {
    localStorage.setItem(itemName, JSON.stringify(value))
  }

  static getItem(itemName: LocalStorageItem) {
    return localStorage.getItem(itemName) ? JSON.parse(localStorage.getItem(itemName)!) : undefined
  }
}
