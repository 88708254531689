import { getConfig, getKeycloackRootUrl, getParceirosBffApiRootUrl } from '../../config/config'
import KeycloakService from '../keycloak/Keycloak.service'
import ApplicationEngineService from './applicationEngine/applicationEngine.service'
import AuthService from './auth/auth.service'
import BackofficeService from './backoffice/backoffice.service'
import BackofficeOriginationsService from './backofficeOriginations/backofficeOriginations.service'
import BillingsService from './billings/billings.service'
import { BiometryService } from './biometry/biometry.service'
import BorrowersService from './borrowers/borrowers.service'
import BudgetsService from './budgets/budgets.service'
import ConfigurationsService from './configurations/configurations.service'
import CoordinatorService from './coordinator/coordinator.service'
import CouponsService from './coupons/coupons.service'
import DatalakeAcessoService from './datalake-acesso/datalake-acesso.service'
import OperatorServiceCenterService from './operatorServiceCenter/operator-service-center.service'
import OriginationsService from './originations/originations.service'
import RetailersService from './retailers/retailers.service'
import RetailersOnboardingService from './retailersOnboarding/retailers-onboarding.service'
import SmartphoneSaleCreditService from './smartphone-sale/smartphone-sale-credit.service'
import TransactionalCreditService from './transactional-credit/transactional-credit.service'
import TransactionsService from './transactions/transactions.service'
import { UsersService } from './users/users.service'

type ApiService = {
  auth: AuthService
  backoffice: BackofficeService
  backofficeOriginations: BackofficeOriginationsService
  billings: BillingsService
  datalakeAcesso: DatalakeAcessoService
  retailers: RetailersService
  coupons: CouponsService
  coordinator: CoordinatorService
  borrowers: BorrowersService
  applicationEngine: ApplicationEngineService
  transactions: TransactionsService
  originations: OriginationsService
  budgets: BudgetsService
  configurations: ConfigurationsService
  users: UsersService
  transactionalCredit: TransactionalCreditService
  operatorServiceCenter: OperatorServiceCenterService
  smartphoneSaleCredit: SmartphoneSaleCreditService
  retailersOnboarding: RetailersOnboardingService
  biometry: BiometryService
}

export const bffParceirosApiService: ApiService = {
  auth: new AuthService(),
  backoffice: new BackofficeService(),
  backofficeOriginations: new BackofficeOriginationsService(),
  billings: new BillingsService(),
  datalakeAcesso: new DatalakeAcessoService(),
  retailers: new RetailersService(),
  coupons: new CouponsService(),
  applicationEngine: new ApplicationEngineService(),
  borrowers: new BorrowersService(),
  coordinator: new CoordinatorService(),
  transactions: new TransactionsService(),
  budgets: new BudgetsService(),
  originations: new OriginationsService(),
  configurations: new ConfigurationsService(),
  users: new UsersService(),
  transactionalCredit: new TransactionalCreditService(),
  operatorServiceCenter: new OperatorServiceCenterService(),
  smartphoneSaleCredit: new SmartphoneSaleCreditService(),
  retailersOnboarding: new RetailersOnboardingService(),
  biometry: new BiometryService(),
}

const startBffParceirosApiService = (): void => {
  const authToken = localStorage.getItem('id_token')
  const authConfig = getConfig().bff.authorization
  const url = getParceirosBffApiRootUrl()

  if (!authToken) {
    bffParceirosApiService.auth._init(url, null)
    bffParceirosApiService.budgets._init(url, authConfig)
  } else {
    bffParceirosApiService.auth._init(url, authToken)
    bffParceirosApiService.backoffice._init(url, authToken)
    bffParceirosApiService.retailers._init(url, authToken)
    bffParceirosApiService.backofficeOriginations._init(url, authToken)
    bffParceirosApiService.billings._init(url, authToken)
    bffParceirosApiService.datalakeAcesso._init(url, authToken)
    bffParceirosApiService.coupons._init(url, authToken)
    bffParceirosApiService.coordinator._init(url, authToken)
    bffParceirosApiService.borrowers._init(url, authToken)
    bffParceirosApiService.applicationEngine._init(url, authToken)
    bffParceirosApiService.transactions._init(url, authToken)
    bffParceirosApiService.originations._init(url, authToken)
    bffParceirosApiService.configurations._init(url, authToken)
    bffParceirosApiService.users._init(url, authToken)
    bffParceirosApiService.budgets._init(url, authConfig)
    bffParceirosApiService.transactionalCredit._init(url, authToken)
    bffParceirosApiService.operatorServiceCenter._init(url, authToken)
    bffParceirosApiService.smartphoneSaleCredit._init(url, authToken)
    bffParceirosApiService.retailersOnboarding._init(url, authToken)
    bffParceirosApiService.biometry._init(url, authToken)

    bffParceirosApiService.auth.decodeJwt()
  }
}

export const startSSOAuthService = async (token: string): Promise<void> => {
  const url = getParceirosBffApiRootUrl()

  if (token) {
    bffParceirosApiService.auth._init(url, null)
    await bffParceirosApiService.auth.exchangeTokens(token)
  }
}

export const startApiServices = (): void => {
  startBffParceirosApiService()
}

export const keyCloackService = new KeycloakService()
keyCloackService.init(getKeycloackRootUrl())
