import SafeListService from '../../common/assets/utils/SafeListService'
import { bffParceirosApiService } from '../../services/bff'
import { ApplicationStatus } from '../../services/bff/applicationEngine/dto/Application.dto'
import { DeniedCreditStatus } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'

export default class TransactionalCreditHelper {
  static REEVALUATION_INTERVAL_CHECK = 1000
  static AVAILABLE_LIMIT_TRESHOLD = 10

  static async runPreChecks(document: string, storeId: string) {
    if (!SafeListService.shouldRunApplicationReavaluationStoresSafeList(storeId)) {
      return
    }

    const preApplication = await bffParceirosApiService.applicationEngine.preApplication({
      cpf: document,
      storeId,
    })

    const borrowerId = preApplication?.borrower?.id
    if (!borrowerId) {
      return
    }

    if (preApplication.shouldReevaluate) {
      await bffParceirosApiService.applicationEngine.handleReevaluation({ borrowerId, storeId })
    }

    if (preApplication.application?.status === ApplicationStatus.UNDER_ANALYSIS) {
      await bffParceirosApiService.applicationEngine.handleApplicationInProgress(borrowerId)
    }
  }

  static defineDeniedStatus(hasCompletedApplication: boolean, borrowerAvailableLimit: number): DeniedCreditStatus {
    if (!hasCompletedApplication) {
      return DeniedCreditStatus.PRE_APPLICATION_APPROVED_AND_EVALUATION_DENIED
    }

    if (borrowerAvailableLimit >= TransactionalCreditHelper.AVAILABLE_LIMIT_TRESHOLD) {
      return DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_ABOVE_THRESHOLD
    }

    return DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_BELOW_THRESHOLD
  }
}
