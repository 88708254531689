import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { isCompletedApplication } from '../../common/ApplicationHelper'
import ApplicationSliceReducer from '../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import {
  AggregatedTransactionalCreditEvaluation,
  ApplicationSetupStatusEnum,
  EvaluationResultEnum,
} from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { TransactionalCreditApplicationDeniedResult } from './components/TransactionalCreditApplicationDeniedResult/TransactionalCreditApplicationDeniedResult'
import { TransactionalCreditApprovedResult } from './components/TransactionalCreditApprovedResult/TransactionalCreditApprovedResult'
import { TransactionalCreditDeniedResult } from './components/TransactionalCreditDeniedResult/TransactionalCreditDeniedResult'

enum Result {
  AllApproved,
  ApplicationDenied,
  TransactionalCreditDenied,
}

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  onRedo: () => void
}

const TransactionalCreditResult: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const { evaluationResult, onRedo } = props

  const { isLoadingPreApplication, preApplicationStatus } = useTypedSelector(
    state => {
      return {
        isLoadingPreApplication: state.application.isLoadingPreApplication,
        preApplicationStatus: state.application?.preApplicationResponse?.status,
      }
    }
  )

  useEffect(() => {
    dispatch(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: evaluationResult.evaluation.document }));
  }, []);

  const defineResult = (evaluationResult: AggregatedTransactionalCreditEvaluation) => {
    if (
      evaluationResult.evaluation.result == EvaluationResultEnum.APPROVED ||
      evaluationResult.evaluation.result == EvaluationResultEnum.PARTIALLY_APPROVED
    ) {
      return Result.AllApproved
    }
    return evaluationResult.applicationSetup?.status == ApplicationSetupStatusEnum.DENIED
      ? Result.ApplicationDenied
      : Result.TransactionalCreditDenied
  }

  const handleGoToApplication = (cpf: string, analyticsEvent?: AnalyticsEvents) => {
    if (analyticsEvent) analyticsService.sendEvent(analyticsEvent)
    window.location.href = `/applications/${cpf}`
  }

  if (isLoadingPreApplication) {
    return <LoadingSpinner text="Analisando proposta..."/>
  }

  const hasCompletedApplication = !!preApplicationStatus && isCompletedApplication(preApplicationStatus)

  const result = defineResult(evaluationResult)

  switch (result) {
    case Result.AllApproved: {
      return (
        <TransactionalCreditApprovedResult
          evaluationResult={evaluationResult}
          hasCompletedApplication={hasCompletedApplication}
          handleGoToApplication={() => handleGoToApplication(evaluationResult.evaluation.document, AnalyticsEvents.sp_approved_start_application)}
          handleGoBackToStart={onRedo}
        />
      )
    }
    case Result.ApplicationDenied: {
      return <TransactionalCreditApplicationDeniedResult handleGoBackToStart={onRedo} />
    }
    case Result.TransactionalCreditDenied: {
      return (
        <TransactionalCreditDeniedResult
          evaluationResult={evaluationResult}
          hasCompletedApplication={hasCompletedApplication}
          handleGoToApplication={() => handleGoToApplication(evaluationResult.evaluation.document, AnalyticsEvents.sp_denied_start_application)}
          handleGoBackToStart={onRedo}
        />
      )
    }
  }
}

export default TransactionalCreditResult
