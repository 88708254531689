export interface SubmitBiometryLivenessRequestDTO {
  biometryObject: string
  borrowerId: string
}

export enum BiometryLivenessStatus {
  PENDING = 'PENDING',
  MUST_RETRY = 'MUST_RETRY',
  COMPLETED = 'COMPLETED',
}

export interface GetBiometryLivenessStatusResponseDTO {
  status: BiometryLivenessStatus
}
