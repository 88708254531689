import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../../../cash-register/common/formatBRL'
import ProgressBar from '../../../../molecules/ProgressBar/ProgressBar'
import InfoRow from '../../../../molecules/Row/InfoRow'
import { blue, green } from '../../../../ui/utils/_colors'

interface TransactionalCreditProgressBarProps {
  recurrenceAvailableLimit: number
  evaluationMaxAmount?: number
}

const TransactionalCreditProgressBar: React.FC<TransactionalCreditProgressBarProps> = ({ recurrenceAvailableLimit, evaluationMaxAmount }) => {
  const evaluationLimit = evaluationMaxAmount ?? 0

  return (
    <MainContainer>
      <ProgressBar
        primaryBar={{ value: recurrenceAvailableLimit, title: formatBRL(recurrenceAvailableLimit), color: green.confirmation }}
        secondaryBar={{ value: evaluationLimit, title: formatBRL(evaluationLimit), color: blue.cobalt }}
      />

      <InfoRow color={green.confirmation} title={'Limite recorrente'} value={formatBRL(recurrenceAvailableLimit)} subtitle={'Limite disponível do cliente'} />
      <InfoRow color={blue.cobalt} title={'Limite extra aprovado'} value={formatBRL(evaluationLimit)} subtitle={'Limite válido para uma única compra nesta loja'} />
    </MainContainer>
  )
}

export default TransactionalCreditProgressBar

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  text-align: left;
`
