import { Button } from 'antd'
import React from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'
import LoadingSpinner from '../../../atoms/Loading/LoadingSpinner'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import UmeLinks from '../../../common/UmeLinks'
import { ApplicationCoupon } from '../../../services/bff/coordinator/dto/Borrowers.dto'
import { SourceProduct } from '../../../services/bff/coordinator/dto/Contracts.dto'
import { TransactionalCreditEvaluation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import CouponDetails from '../../OperatorServiceCenter/CouponDetails'
import ApplicationWithSmartphoneSale from '../ApplicationWithSmartphoneSale'
import ApplicationWithTransactionalCreditEvaluation from '../ApplicationWithTransactionalCreditEvaluation'

interface Props {
  borrowerLimit?: number
  availableLimit?: number
  applicationCoupon?: ApplicationCoupon
  document?: string
  isFetchingBorrowerLimit: boolean
  transactionalCreditEvaluation?: TransactionalCreditEvaluation
  handleReset: () => void
}

const ApplicationApprovedComponent: React.FC<Props> = props => {
  const { handleReset, transactionalCreditEvaluation, availableLimit, applicationCoupon, borrowerLimit, document, isFetchingBorrowerLimit } = props

  if (isFetchingBorrowerLimit) {
    return <LoadingSpinner text="Carregando..." />
  }

  if (transactionalCreditEvaluation?.sourceProduct === SourceProduct.SMARTPHONE_SALE) {
    return (
      <Container>
        <ApplicationWithSmartphoneSale cpf={document} />
      </Container>
    )
  }

  if (transactionalCreditEvaluation?.sourceProduct === SourceProduct.SUPER_PURCHASE) {
    return <ApplicationWithTransactionalCreditEvaluation evaluation={transactionalCreditEvaluation} borrowerLimit={borrowerLimit} availableLimit={availableLimit} />
  }

  return (
    <Container>
      <HeaderContainer>
        <Title>PARABÉNS!</Title>
        <SubTitle>
          Bem-vindo à <BoldGreenText>Ume!</BoldGreenText>
          <br />
          Você já pode aproveitar seu crédito para comprar em nossa rede de lojas parceiras!
        </SubTitle>
      </HeaderContainer>
      <LimitContainer>
        <React.Fragment>
          <LimitTitle> Limite total: </LimitTitle>
          <LimitValue>{borrowerLimit ? formatBRL(borrowerLimit) : 'Erro ao carregar o limite, volte a tela inicial e consulte pelo CPF'}</LimitValue>
        </React.Fragment>
      </LimitContainer>
      {!isFetchingBorrowerLimit && applicationCoupon && <CouponDetails coupon={applicationCoupon} showStoreAlert={false} />}
      <QRCodeContainer>
        <QRCodeDescription>Solicite ao cliente a leitura do QR Code para baixar o aplicativo da Ume para Android:</QRCodeDescription>
        <QRCode style={{ marginTop: '2vh', width: '100%' }} value={UmeLinks.STORE_APPLICATION_ANDROID_APP_URL} />
      </QRCodeContainer>
      <BackButton onClick={handleReset}> Voltar para o cadastro </BackButton>
    </Container>
  )
}

export default ApplicationApprovedComponent

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 32px 24px 32px 24px;
  justify-content: space-between;

  text-align: left;
  height: 93%;

  // For Desktop
  max-width: 400px;
  margin: auto;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 0.18px;

  color: #11b048;
`

const BoldGreenText = styled.strong`
  color: #11b048;
`

const SubTitle = styled.span`
  margin-top: 2vh;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: 0.1px;

  color: #000000;
`

const LimitContainer = styled.div`
  // margin-left: 5vw;

  display: flex;
  flex-direction: column;
`

const LimitTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`

const LimitValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #11b048;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingText = styled.p`
  color: #11b048;
`

const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 20px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 90% !important;

  align-self: center;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`

const QRCodeContainer = styled.div``

const QRCodeDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #111814;
`
