import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CameraOpener, ErrorPictureResponse, SuccessPictureResponse } from 'unico-webframe'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import BiometryLivenessReducer from '../../redux/reducers/biometry/biometry-liveness.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import UnicoSDKService from '../../services/unico/UnicoSdkService'
import BiometryLivenessErrorPage from './BiometryLivenessErrorPage'
import BiometryLivenessInstructionPage from './BiometryLivenessInstructionPage'
import BiometryLivenessResultPage from './BiometryLivenessResultPage'

const BiometryLivenessPage = () => {
  const dispatch = useDispatch()

  const [sdkCameraInstance, setSdkCameraInstance] = useState<UnicoSDKService | null>(null)
  const [hasReadBiometryInstructions, setHasReadBiometryInstructions] = useState<boolean>(false)

  const { biometryLivenessStatus, isPoolingBiometry, isSubmittingBiometry } = useTypedSelector(state => ({
    biometryLivenessStatus: state.biometryLiveness.biometryLivenessStatus,
    isSubmittingBiometry: state.biometryLiveness.isSubmittingBiometryLiveness,
    isPoolingBiometry: state.biometryLiveness.isPoolingBiometryLivenessResult,
  }))

  const [hasAnErrorOccurred, setHasAnErrorOccurred] = useState<boolean>(false)

  useEffect(() => {
    setSdkCameraInstance(new UnicoSDKService())
  }, [])

  const openCamera = async () => {
    sdkCameraInstance?.camera?.then((camOpener: CameraOpener) => {
      camOpener.open({
        on: {
          success: (obj: SuccessPictureResponse) => {
            setHasAnErrorOccurred(false)
            if (obj.base64.length) {
              dispatch(BiometryLivenessReducer.actions.submitBiometryLiveness({ biometryObject: obj.encrypted }))
            }
          },
          error: (error: ErrorPictureResponse) => {
            setHasAnErrorOccurred(true)
          },
        },
      })
    })
  }

  const handleNextOnBiometryInstructions = () => {
    setHasReadBiometryInstructions(true)
    openCamera()
  }

  const handleTryAgain = () => {
    setHasReadBiometryInstructions(false)
  }

  const isLoading = isPoolingBiometry || isSubmittingBiometry

  if (biometryLivenessStatus) {
    return <BiometryLivenessResultPage biometryLivenessStatus={biometryLivenessStatus} onTryAgain={handleTryAgain} />
  }

  if (!hasReadBiometryInstructions) {
    return <BiometryLivenessInstructionPage handleNext={handleNextOnBiometryInstructions} />
  }

  if (isLoading) {
    return <LoadingSpinner text={'processando...'} />
  }

  if (hasAnErrorOccurred) {
    return <BiometryLivenessErrorPage onTryAgain={handleTryAgain} />
  }

  return (
    <CameraContainer>
      <div id="box-camera"></div>
    </CameraContainer>
  )
}

export default BiometryLivenessPage

const CameraContainer = styled.div`
  width: 100%;
  height: 100%;
`
