export enum Role {
  REPRESENTATIVE = 'representative',
  CASHIER = 'cashier',
  MANAGER = 'manager',
  ATTENDANT = 'attendant',
  ANALYST = 'analyst',
  RETAILER_ANALYST = 'retailer-analyst',
  RISK_ANALYST = 'risk-analyst',
  PROMOTER = 'promoter',
}

export const OperatorsRolesToManager = ['representative', 'cashier', 'attendant']
export const OperatorsRolesToAnalyst = ['representative', 'cashier', 'attendant', 'manager', 'promoter']

export const ALL_ROLES = [
  Role.ANALYST,
  Role.CASHIER,
  Role.MANAGER,
  Role.REPRESENTATIVE,
  Role.RETAILER_ANALYST,
  Role.RISK_ANALYST,
  Role.ATTENDANT,
  Role.PROMOTER,
]

export const RETAILER_ROLES = [
  Role.CASHIER,
  Role.MANAGER,
  Role.REPRESENTATIVE,
  Role.RETAILER_ANALYST,
  Role.ATTENDANT,
  Role.PROMOTER,
]

export const UME_ROLES = [Role.ANALYST, Role.RISK_ANALYST]
export const OPERATOR_SALES_ROLES = [Role.REPRESENTATIVE, Role.PROMOTER]
