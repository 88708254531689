import { IApplicationResponse } from '@scudraservicos/coordinator-client/dist/src/services/applications/interfaces/Application.res'
import { IBorrowerResponse } from '@scudraservicos/coordinator-client/dist/src/services/borrowers-api/interfaces/BorrowersApi.res'
import { PreApplicationConfig } from '../../../services/bff/applicationEngine/dto/PreApplication.dto'

export type IApplicationFormField = 'cpf' | 'phoneNumber' | 'secondaryPhoneNumber' | 'verificationCode' | 'name' | 'cep' | 'email' | 'birthDate' | 'profession'

export const removeCountryDigitsFromPhoneNumber = (phoneNumber: string) => {
  // Remove everything from index: 0,1,2
  return phoneNumber.slice(3, phoneNumber.length)
}

export const removeCountryDigitsFromNullablePhoneNumber = (phoneNumber?: string) => {
  return phoneNumber ? removeCountryDigitsFromPhoneNumber(phoneNumber) : undefined
}

export enum ApplicationVersionEnum {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

export type ApplicationVersion = ApplicationVersionEnum.V1 | ApplicationVersionEnum.V2 | ApplicationVersionEnum.V3

export interface IPreApplicationResponse {
  borrower: BorrowerWithStatus
  limit: number
  status: PRE_APPLICATION_STATUS
  reason: string
  application?: IApplicationResponse
  applicationVersion: ApplicationVersion
  biometryStatus?: BiometryStatus
  config?: PreApplicationConfig
}

export enum BiometryStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CONFLICT = 'CONFLICT',
  CANCELED = 'CANCELED',
  SPOOF = 'SPOOF',
}

export enum APPLICATION_STATUS {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  FAILURE = 'FAILURE',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  WAITING_MANUAL_APPROVAL = 'WAITING_MANUAL_APPROVAL',
  PRE_APPROVED = 'PRE_APPROVED',
  PRE_APPLICATION_DENIED = 'PRE_APPLICATION_DENIED',
  PRE_APPLICATION_APPROVED = 'PRE_APPLICATION_APPROVED',
}

export enum PRE_APPLICATION_STATUS {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  WAITING_MANUAL_APPROVAL = 'WAITING_MANUAL_APPROVAL',
  PRE_APPLICATION_APPROVED = 'PRE_APPLICATION_APPROVED',
  PRE_APPLICATION_DENIED = 'PRE_APPLICATION_DENIED',
  PRE_APPLICATION_CONTINUE = 'PRE_APPLICATION_CONTINUE',
  PRE_APPROVED = 'PRE_APPROVED',
}
export enum BorrowerStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Incomplete = 'INCOMPLETE',
  UnderAnalysis = 'UNDER_ANALYSIS',
  ManualAnalysis = 'MANUAL_ANALYSIS',
  PreApproved = 'PRE_APPROVED',

  PreApplicationContinue = 'PRE_APPLICATION_CONTINUE',
  PreApplicationApproved = 'PRE_APPLICATION_APPROVED',
  PreApplicationDenied = 'PRE_APPLICATION_DENIED',
}

export enum PhoneStatus {
  VerifiedPhone = 'VERIFIED_PHONE',
  MissingPhone = 'MISSING_PHONE',
  FilledPhone = 'FILLED_PHONE',
  ConfirmedPhone = 'CONFIRMED_PHONE',
}

export interface BorrowerSettings {
  id: string
  createdOn: string
  modifiedOn: string
  borrowerId: string
  disablePurchase: boolean
}

export interface BorrowerWithStatus extends IBorrowerResponse {
  borrowerStatus: BorrowerStatus
  phoneStatus: PhoneStatus
  unverifiedPhoneNumber?: string
  enableHighRecurrence: boolean
  billDueDay?: number
  borrowerSettings?: BorrowerSettings
}
