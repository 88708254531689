import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LocalStorageWrapper, { LocalStorageItem } from '../../../../common/assets/utils/LocalStorageWrapper'
import { formatPhoneNumber } from '../../../../common/FormatPhone'
import { storeConfirmation } from '../../../../common/StoreConfirmation'
import { emailIsValid } from '../../../../common/Validators'
import * as config from '../../../../config/config'
import { operatorsSetupActive } from '../../../../config/config'
import { LoadingScreen } from '../../../../legacy-lib/design-kit-ui'
import LoggedUserSaverService from '../../../../molecules/LoggedUserInfoCard/LoggedUserSaverService'
import { OperatorCenterHelper } from '../../../../pages/Operators/OperatorCenterHelper'
import ConfigurationsSliceReducer from '../../../../redux/reducers/configurations/configurations.reducer'
import { bffParceirosApiService, keyCloackService, startApiServices, startSSOAuthService } from '../../../../services/bff'
import { black, gray } from '../../../../ui/utils/_colors'
import WhatsAppUtils from '../../../../utils/whatsApp.utils'
import EmailStep from '../EmailStep/EmailStep'
import KeycloackLogin from '../Keycloack/KeycloackLogin'
import PasswordStep from '../PasswordStep/PasswordStep'
import { ForgotPassword, Link, LoginBox, LoginCard, Message, MessageIcon, Row } from './style'

export enum LoginFormStep {
  EMAIL_STEP,
  PASSWORD_STEP,
}

export interface ILoginFormProps {
  title?: string
  history?: any
  location?: any
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export interface ILoginFormState {
  formStep: LoginFormStep
  username: string
  password: string
  showPassword: boolean
  loading: boolean
  passwordAuthenticationError: string
  ssoAuthenticationError: string
}

const LoginForm: React.FC<ILoginFormProps> = (props: ILoginFormProps) => {
  const { handleUserLogged } = props

  const [formStep, setFormStep] = useState<LoginFormStep>(LoginFormStep.EMAIL_STEP)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordAuthenticationError, setPasswordAuthenticationError] = useState<string>('')
  const [ssoAuthenticationError, setSSOAuthenticationError] = useState<string>('')

  const dispatch = useDispatch()

  const onStepChange = (step: LoginFormStep) => {
    if (!username) {
      setPasswordAuthenticationError('Preencha o campo com seu e-mail')
    } else if (!emailIsValid(username)) {
      setPasswordAuthenticationError('Por favor, insira um e-mail válido')
    } else {
      setPasswordAuthenticationError('')
      setFormStep(step)
    }
  }

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setPasswordAuthenticationError('')
    setUsername(e.target.value)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    setPasswordAuthenticationError('')
    setPassword(e.target.value)
  }

  const loadConfigurations = () => {
    dispatch(ConfigurationsSliceReducer.actions.fetchConfigurations({}))
  }

  const handlePasswordAuthentication = async () => {
    if (!username) {
      setPasswordAuthenticationError('Preencha o campo com seu e-mail')
      return
    }

    if (!password) {
      setPasswordAuthenticationError('Preencha o campo com sua senha')
      return
    }

    setLoading(true)

    try {
      await bffParceirosApiService.auth.login({ username, password })

      startApiServices()

      const user = await bffParceirosApiService.auth.decodeJwt()

      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)

      if (operatorsSetupActive && !operator.active) {
        setLoading(false)
        setPasswordAuthenticationError('Usuário bloqueado')
        await bffParceirosApiService.auth.logout()
      }

      LocalStorageWrapper.setItem(LocalStorageItem.OPERATOR, operator)

      const isStoreSetUp = bffParceirosApiService.coordinator.isStoreSetUp()

      if (!isStoreSetUp) {
        const store = await bffParceirosApiService.retailers.fetchStoreById(operator.storeId)
        storeConfirmation(store)

        loadConfigurations()
      }

      await LoggedUserSaverService.fetchAndSaveUser()

      setLoading(false)
      handleUserLogged(user)
    } catch (error) {
      setLoading(false)

      setPasswordAuthenticationError(error.message)

      if (error.message === 'UNAUTHORIZED') {
        setFormStep(LoginFormStep.EMAIL_STEP)
        setPassword('')
        setPasswordAuthenticationError('Usuário não cadastrado ou senha incorreta')
      }
    }
  }

  const handleSSOAuthentication = async () => {
    setLoading(true)

    try {
      const keycloakLoginToken = await keyCloackService.getLoginToken()

      if (!keycloakLoginToken) {
        setLoading(false)
        setSSOAuthenticationError('Erro ao autenticar, tente novamente.')
      }

      await startSSOAuthService(keycloakLoginToken)

      startApiServices()

      const user = await bffParceirosApiService.auth.decodeJwt()

      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)

      if (operatorsSetupActive && !operator.active) {
        setLoading(false)
        setSSOAuthenticationError('Usuário bloqueado')
        await bffParceirosApiService.auth.logout()
      }

      LocalStorageWrapper.setItem(LocalStorageItem.OPERATOR, operator)

      const isStoreSetUp = bffParceirosApiService.coordinator.isStoreSetUp()

      if (!isStoreSetUp) {
        const storeId = operator.storeId ? operator.storeId : user.storeId!
        const store = await bffParceirosApiService.retailers.fetchStoreById(storeId)

        storeConfirmation(store)
      }

      await LoggedUserSaverService.fetchAndSaveUser()

      setLoading(false)
      handleUserLogged(user)
    } catch (error) {
      setLoading(false)
      setSSOAuthenticationError(error.message)

      if (error.message === 'UNAUTHORIZED') {
        setSSOAuthenticationError('Usuário sem autorização')
      }
    }
  }

  useEffect(() => {
    const localStorageOperator = LocalStorageWrapper.getItem(LocalStorageItem.OPERATOR)
    const localStorageConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.CONFIGURATIONS)

    if (bffParceirosApiService.auth.isLogged() && localStorageOperator) {
      OperatorCenterHelper.redirectToOperatorHome(localStorageConfigurations, localStorageOperator.role)
    }
  }, [handlePasswordAuthentication, handleSSOAuthentication])

  const steps = {
    0: <EmailStep onUsernameChange={onUsernameChange} username={username} onStepChange={onStepChange} error={passwordAuthenticationError} />,
    1: <PasswordStep onPasswordChange={onPasswordChange} password={password} onStepChange={onStepChange} onSubmit={handlePasswordAuthentication} error={passwordAuthenticationError} />,
  }

  return (
    <LoginBox align="center" justify="center">
      <LoginCard boxShadow={false}>
        {steps[formStep]}

        <KeycloackLogin handleLoginButtonPress={handleSSOAuthentication} error={ssoAuthenticationError} />

        <ForgotPassword>
          <Row>
            <MessageIcon icon={'chat'} size={20} />
            <Message color={black.text}>Esqueceu seus dados de acesso?</Message>
          </Row>

          <Message color={gray.text}>
            Fale conosco pelo Atendimento ao Varejo Ume pelo{' '}
            <Link href={WhatsAppUtils.buildUrl(config.UME_PHONES.retailerSAC.whatsapp)} target="_blank">
              WhatsApp {formatPhoneNumber(config.UME_PHONES.retailerSAC.phoneNumber)}.
            </Link>
          </Message>
        </ForgotPassword>
      </LoginCard>
      {loading && <LoadingScreen />}
    </LoginBox>
  )
}

export default LoginForm
