import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { OperatorServiceCenterBorrowerResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import { black, gray } from '../../../ui/utils/_colors'
import DocumentUtils from '../../../utils/document.utils'
import PhoneUtils from '../../../utils/phone.utils'

interface IBorrower {
  borrower?: OperatorServiceCenterBorrowerResponse
}

const OperatorServiceCenterBorrowerInfo: React.FC<IBorrower> = props => {
  const { borrower } = props

  return (
    <MainContainer>
      <BorrowerContainer>
        <Title>{borrower?.borrower.name}</Title>
        <BorrowerInfoContainer>
          <BorrowerInfoText>{DocumentUtils.maskCpf(borrower?.borrower.document)}</BorrowerInfoText>
          <BorrowerInfoText> | </BorrowerInfoText>
          <BorrowerInfoText>{PhoneUtils.maskPhone(borrower?.borrower.phoneNumber)}</BorrowerInfoText>
        </BorrowerInfoContainer>
      </BorrowerContainer>

      <LimitContainer>
        <Text>Valor total disponível para comprar</Text>
        <Value>{formatBRL(borrower?.credit.availableLimit || 0)}</Value>
      </LimitContainer>
    </MainContainer>
  )
}

export default OperatorServiceCenterBorrowerInfo

const MainContainer = styled.div`
  width: 100%;
  height: auto;
`

const BorrowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: auto;
  height: auto;
`

const BorrowerInfoContainer = styled.div`
  flex-direction: row;
  width: 100%;
  height: auto;
`

const LimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 24px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  color: ${black.primary};
  line-height: 24px;
  text-align: left;
`

const BorrowerInfoText = styled.text`
  font-family: Inter;
  font-size: 14px;
  color: ${gray.scale700};
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.001em;
`

const Text = styled.text`
  font-family: Inter;
  font-size: 12px;
  color: ${gray.scale700};
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`

const Value = styled.text`
  font-family: Inter;
  font-size: 24px;
  color: ${black.primary};
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin-top: 8px;
`
