import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import React from 'react'
import styled from 'styled-components'
import { LoadingIcon } from '../../../atoms/LoadingIcon/LoadingIcon'
import { ReactComponent as Info } from '../../../common/assets/images/info.svg'
import { black, gray, white } from '../../../ui/utils/_colors'

interface OperatorPerformanceCardProps {
  isLoading: boolean
  title: string
  text: string
  toolTipText?: string
  placement: TooltipPlacement
}

const OperatorPerformanceCard: React.FC<OperatorPerformanceCardProps> = (props: OperatorPerformanceCardProps) => {
  return (
    <Card>
      {props.isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <CardLabel>{props.text}</CardLabel>
          <CardTitle>{props.title}</CardTitle>
          {props.toolTipText ? (
            <Tooltip overlayInnerStyle={tooltipInnerStyle} placement={props.placement} title={<ToolTipText>{props.toolTipText}</ToolTipText>} color="#202124">
              <InfoIcon />
            </Tooltip>
          ) : (
            undefined
          )}
        </>
      )}
    </Card>
  )
}

export default OperatorPerformanceCard

const InfoIcon = styled(Info)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  fill: #aab6bf;
  cursor: pointer;
`

const ToolTipText = styled.p`
  color: ${white.primary};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`

const Card = styled.div`
  background: ${white.primary};
  border: 1px solid ${gray.scale300};
  padding: 16px;
  border-radius: 8px;
  position: relative;

  &:nth-child(odd):last-child {
    grid-column: span 2;
  }
`
const CardTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: ${gray.text};
  margin-top: 8px;
`

const CardLabel = styled.div`
  color: ${black.text2};
  text-align: left;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`
const tooltipInnerStyle: React.CSSProperties = {
  borderRadius: '8px',
  padding: '16px',
  maxWidth: '168px',
}
