import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import StoreConfiguration from '../manager-config/components/StoreConfiguration'
import { PagesPathEnum } from '../pages/enums/pages-path.enum'
import { bffParceirosApiService, startApiServices } from '../services/bff'
import { Role } from './constants'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  authorizedRoles: Role[]
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, authorizedRoles, ...rest }) => {
  const logged = bffParceirosApiService.auth.isLogged()
  const authorized = bffParceirosApiService.auth.isAuthorized(authorizedRoles)
  const isStoreConfigured = bffParceirosApiService.coordinator.isStoreSetUp()

  const renderComponent = (props: RouteComponentProps<any>) => {
    startApiServices()

    if (!logged) {
      return <Redirect to={{ pathname: PagesPathEnum.LOGIN, state: { from: props.location } }} />
    }

    if (!authorized) {
      return <Redirect to={{ pathname: PagesPathEnum.HOME_PAGE }} />
    }

    if (!isStoreConfigured) {
      return <StoreConfiguration />
    }

    return <Component {...props} />
  }

  return <Route {...rest} render={renderComponent} />
}

export default PrivateRoute
