import React from 'react'
import styled from 'styled-components'
import { EvaluationResultEnum } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { black, gray, green, orange } from '../../../../ui/utils/_colors'

interface AlertBoxProps {
  evaluationResult: EvaluationResultEnum
  evaluationExpirationDate?: string
  evaluationStore?: string
}

const AlertBox: React.FC<AlertBoxProps> = ({ evaluationResult, evaluationExpirationDate, evaluationStore }) => {
  const defineAlertBoxData = () => {
    switch (evaluationResult) {
      case EvaluationResultEnum.APPROVED:
      case EvaluationResultEnum.PARTIALLY_APPROVED:
        return {
          title: 'Super Compra disponível',
          text: (
            <>
              Proposta ativa no caixa até {evaluationExpirationDate} na loja <BoldText>{evaluationStore}</BoldText>.
            </>
          ),
          cardStyle: {
            backgroundColor: green.light4,
            borderColor: green.lemon,
          },
        }
      case EvaluationResultEnum.DENIED:
        return {
          title: 'Super Compra não foi aprovada',
          text: 'O limite extra da Super Compra não foi aprovado, mas o cliente pode aproveitar o limite concedido.',
          cardStyle: { backgroundColor: orange.light, borderColor: orange.amber1 },
        }
    }
  }

  const alertBoxData = defineAlertBoxData()

  return (
    <MainContainer backgroundColor={alertBoxData.cardStyle.backgroundColor} borderColor={alertBoxData.cardStyle.borderColor}>
      <InfoContainer>
        <Title>{alertBoxData.title}</Title>
        <Text>{alertBoxData.text}</Text>
      </InfoContainer>
    </MainContainer>
  )
}

export default AlertBox

interface AlertBoxStyleProps {
  backgroundColor: string
  borderColor: string
}

const MainContainer = styled.div<AlertBoxStyleProps>`
  background: ${(props: AlertBoxStyleProps) => props.backgroundColor};
  border: 1px solid ${(props: AlertBoxStyleProps) => props.borderColor};
  border-radius: 4px;
  text-align: left;
`

const InfoContainer = styled.text`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 24px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${black.primary};
`

const Text = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${gray.scale700};
  margin-top: 8px;
`

const BoldText = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${gray.scale700};
`
