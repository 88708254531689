import '@blueprintjs/datetime//lib/css/blueprint-datetime.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createBrowserHistory, History } from 'history'
import 'normalize.css/normalize.css'
import React from 'react'
import { pdfjs } from 'react-pdf'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Redirect, Route, RouteComponentProps, RouteProps, Router, Switch } from 'react-router-dom'
import CashRegisterSelection from './cash-register/CashRegisterSelection'
import Payments from './cash-register/payments/Payments'
import ApprovedPurchase from './cash-register/purchases-old/ApprovedPurchase'
import Purchases from './cash-register/purchases-old/Purchases'
import QRCodePurchase from './cash-register/qrcode-purchase/QRCodePurchase'
import { AppToaster } from './common/AppToaster'
import { ALL_ROLES, Role } from './common/constants'
import PrivateRoute from './common/PrivateRoute'
import RedirectWrapper from './common/RedirectWrapper'
import { isNewLoginActive, isNewRetailerDashActive, managerModuleActivate, operatorsSetupActive } from './config/config'
import './custom-blueprintjs.scss'
import Home from './home/Home'
import Login from './login/Login'
import StoreConfiguration from './manager-config/components/StoreConfiguration'
import ResponsiveHeader from './molecules/header/components/Header/Header'
import { OperatorsForm, OperatorsSetup } from './operators-setup/components'
import ApplicationPage from './pages/Application/ApplicationPage'
import Budget from './pages/Budget/Budget'
import { PagesPathEnum } from './pages/enums/pages-path.enum'
import FinancialPage from './pages/Financial/FinancialPage'
import LoginMultiStep from './pages/Login/Login'
import OperatorConfiguration from './pages/Operators/OperatorConfiguration'
import OperatorServiceCenter from './pages/OperatorServiceCenter/OperatorServiceCenter'
import Receipt from './pages/Receipts/Receipt'
import ReportsPage from './pages/Reports/ReportsPage'
import RetailersDash from './pages/RetailersDash/RetailersDash'
import { SmartphoneSale } from './pages/smartphone-sale/SmartphoneSale'
import TransactionalCredit from './pages/transactional-credit/TransactionalCredit'
import operatorSliceReducer from './redux/reducers/operator/operator.reducer'
import Store from './redux/store'
import { bffParceirosApiService, startApiServices } from './services/bff'
import './ui/css/App.css'
import './ui/css/Carousel.css'
import './ui/css/Spinner.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

require('dotenv').config()

startApiServices()

export const toaster = new AppToaster()

export const history = createBrowserHistory()

export interface HistoryProps {
  history: History
}

interface IAppState {
  currentUser?: ILoginProfileResponse
}

class App extends React.Component<{}, IAppState> {
  state = {
    currentUser: undefined,
  }

  public handleUserLogged = (currentUser?: ILoginProfileResponse) => {
    this.setState({ currentUser })
  }

  render() {
    const user = bffParceirosApiService.auth.getUser()
    const queryClient = new QueryClient()

    if (user) {  
      Store.dispatch(operatorSliceReducer.actions.fetchOperator({ operatorId: user.id }))
    }

    return (
      <Provider store={Store}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <div className="App">
              {user && <ResponsiveHeader user={user} history={history} />}
              <Switch>
                {isNewRetailerDashActive ? (
                  <PrivateRoute
                    authorizedRoles={[
                      Role.REPRESENTATIVE,
                      Role.CASHIER,
                      Role.MANAGER,
                      Role.ATTENDANT,
                      Role.ANALYST,
                      Role.RETAILER_ANALYST,
                      Role.PROMOTER,
                    ]}
                    path="/dashboard"
                    exact={true}
                    component={(props: RouteComponentProps) => (
                      <RetailersDash {...props} userRole={user && (user.role as Role)} />
                    )}
                  />
                ) : (
                  <PrivateRoute
                    authorizedRoles={[
                      Role.REPRESENTATIVE,
                      Role.PROMOTER,
                      Role.CASHIER,
                      Role.MANAGER,
                      Role.ATTENDANT,
                      Role.ANALYST,
                    ]}
                    path="/"
                    exact={true}
                    component={(props: RouteProps) => <Home {...props} user={user}></Home>}
                  />
                )}
                {isNewLoginActive && (
                  <Route
                    path="/login"
                    component={(props: RouteProps) => (
                      <LoginMultiStep {...props} handleUserLogged={this.handleUserLogged}></LoginMultiStep>
                    )}
                  />
                )}
                {!isNewLoginActive && (
                  <Route
                    path="/login"
                    component={(props: RouteProps) => (
                      <Login {...props} handleUserLogged={this.handleUserLogged}></Login>
                    )}
                  />
                )}

                <Route path="/calculadora" component={(props: any) => <Budget {...props} />} />

                <PrivateRoute
                  authorizedRoles={ALL_ROLES}
                  path="/render/:ids/:type"
                  component={(props: any) => <Receipt {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.REPRESENTATIVE, Role.MANAGER, Role.ANALYST, Role.ATTENDANT, Role.PROMOTER]}
                  path="/aquisicao"
                  exact={true}
                  component={() => <Redirect to="/applications" />}
                />

                {managerModuleActivate && (
                  <PrivateRoute
                    authorizedRoles={[
                      Role.MANAGER,
                      Role.ANALYST,
                      Role.REPRESENTATIVE,
                      Role.CASHIER,
                      Role.ATTENDANT,
                      Role.PROMOTER,
                    ]}
                    exact={true}
                    path="/configuracao"
                    component={StoreConfiguration}
                  />
                )}

                {operatorsSetupActive && (
                  <PrivateRoute
                    authorizedRoles={[Role.MANAGER, Role.ANALYST]}
                    exact={true}
                    path="/colaboradores"
                    component={OperatorsSetup}
                  />
                )}
                {operatorsSetupActive && (
                  <PrivateRoute
                    authorizedRoles={[Role.MANAGER, Role.ANALYST]}
                    path="/colaboradores/novo"
                    component={OperatorsForm}
                  />
                )}
                {operatorsSetupActive && (
                  <PrivateRoute
                    authorizedRoles={[Role.MANAGER, Role.ANALYST]}
                    path="/colaboradores/editar"
                    component={OperatorsForm}
                  />
                )}
                <PrivateRoute
                  authorizedRoles={[Role.CASHIER, Role.MANAGER, Role.ANALYST, Role.ATTENDANT]}
                  exact={true}
                  path="/caixa"
                  component={CashRegisterSelection}
                />
                <PrivateRoute
                  authorizedRoles={[Role.CASHIER, Role.MANAGER, Role.ATTENDANT, Role.ANALYST]}
                  path="/caixa/compras"
                  exact
                  component={Purchases}
                />
                <PrivateRoute
                  authorizedRoles={[Role.CASHIER, Role.MANAGER, Role.ATTENDANT, Role.ANALYST]}
                  path={PagesPathEnum.PURCHASE_CONFIRMATION.toString()}
                  exact
                  component={ApprovedPurchase}
                />
                <PrivateRoute
                  authorizedRoles={[Role.CASHIER, Role.MANAGER, Role.ATTENDANT, Role.ANALYST]}
                  path="/caixa/compras/qrcode"
                  exact
                  component={QRCodePurchase}
                />
                <PrivateRoute
                  authorizedRoles={[Role.CASHIER, Role.MANAGER, Role.ANALYST, Role.ATTENDANT, Role.ANALYST]}
                  path="/caixa/pagamentos"
                  exact
                  component={Payments}
                />
                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RETAILER_ANALYST]}
                  path="/financial"
                  component={(props: RouteProps) => <FinancialPage history={history} {...props} />}
                />
                <PrivateRoute
                  authorizedRoles={[
                    Role.CASHIER,
                    Role.MANAGER,
                    Role.ATTENDANT,
                    Role.ANALYST,
                    Role.RETAILER_ANALYST,
                    Role.REPRESENTATIVE,
                    Role.PROMOTER,
                  ]}
                  path={PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE}
                  exact={true}
                  component={() => (
                    <RedirectWrapper currentPath={PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE} component={TransactionalCredit} />
                  )}
                />
                <PrivateRoute
                  authorizedRoles={[
                    Role.CASHIER,
                    Role.MANAGER,
                    Role.ATTENDANT,
                    Role.ANALYST,
                    Role.RETAILER_ANALYST,
                    Role.REPRESENTATIVE,
                    Role.PROMOTER,
                  ]}
                  path="/venda-celular"
                  exact={true}
                  component={SmartphoneSale}
                />
                <PrivateRoute
                  authorizedRoles={[
                    Role.MANAGER,
                    Role.ATTENDANT,
                    Role.ANALYST,
                    Role.RETAILER_ANALYST,
                    Role.REPRESENTATIVE,
                    Role.PROMOTER,
                  ]}
                  path={PagesPathEnum.OPERATOR_SERVICE_CENTER}
                  exact={true}
                  component={() => (
                    <RedirectWrapper currentPath={PagesPathEnum.OPERATOR_SERVICE_CENTER} component={OperatorServiceCenter} />
                  )}
                />
                <PrivateRoute
                  authorizedRoles={[
                    Role.MANAGER,
                    Role.ATTENDANT,
                    Role.ANALYST,
                    Role.RETAILER_ANALYST,
                    Role.REPRESENTATIVE,
                    Role.PROMOTER,
                  ]}
                  path={PagesPathEnum.OPERATOR_CONFIGURATION}
                  exact={true}
                  component={OperatorConfiguration}
                />

                <PrivateRoute
                  authorizedRoles={[
                    Role.CASHIER,
                    Role.MANAGER,
                    Role.ATTENDANT,
                    Role.ANALYST,
                    Role.RETAILER_ANALYST,
                    Role.REPRESENTATIVE,
                    Role.PROMOTER,
                  ]}
                  path="/applications/:cpf?"
                  // TODO - Type
                  component={(props: any) => <ApplicationPage {...props} />}
                />
                <PrivateRoute
                  authorizedRoles={[Role.CASHIER, Role.MANAGER, Role.RETAILER_ANALYST]}
                  exact={true}
                  path="/relatorios"
                  component={ReportsPage}
                />

                {/* Home must be in last in list */}
                <PrivateRoute
                  authorizedRoles={[
                    Role.REPRESENTATIVE,
                    Role.CASHIER,
                    Role.MANAGER,
                    Role.ATTENDANT,
                    Role.ANALYST,
                    Role.RETAILER_ANALYST,
                    Role.PROMOTER,
                  ]}
                  component={(props: RouteProps) => {
                    return <RedirectWrapper currentPath={PagesPathEnum.HOME_PAGE} component={Home} {...props} />
                  }}
                />
              </Switch>
            </div>
          </Router>
        </QueryClientProvider>
      </Provider>
    )
  }
}

export default App
