import React from 'react'
import styled from 'styled-components'
import ApplicationLayout from '../../organisms/Application/ApplicationLayout'

interface Props {
  onTryAgain: () => void
}

const BiometryLivenessErrorPage = ({ onTryAgain }: Props) => (
  <ApplicationLayout title={'Opa, tivemos um problema'} subtitle={'Por favor tente novamente'} disableFooter>
    <TryAgainContainer>
      <TryAgainButton onClick={onTryAgain}> Tentar novamente </TryAgainButton>
    </TryAgainContainer>
  </ApplicationLayout>
)

export default BiometryLivenessErrorPage

const TryAgainContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`

const TryAgainButton = styled.button`
  align-self: center;

  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 80% !important;
`
