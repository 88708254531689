import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as ApprovedCheckSvg } from '../../common/assets/images/approved-check-circle.svg'
import { DateFormat, formatDate, TimeZone } from '../../common/FormatDate'
import { navigateToPath } from '../../common/Navigation'
import { calculateTxCreditTotalAmount } from '../../common/TransactionalCreditHelper'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import BudgetReducer from '../../redux/reducers/budget/budget.reducer'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import { bffParceirosApiService } from '../../services/bff'
import { EvaluationResultEnum, TransactionalCreditEvaluation } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { green, white } from '../../ui/utils/_colors'
import TransactionalCreditResultGenericScreen from '../TransactionalCredit/components/TransactionalCreditResultGenericScreen.tsx'

interface TransactionalCreditEvaluationProps {
  evaluation: TransactionalCreditEvaluation
  borrowerLimit?: number
  availableLimit?: number
}

const ApplicationWithTransactionalCreditEvaluation: React.FC<TransactionalCreditEvaluationProps> = (props: TransactionalCreditEvaluationProps) => {
  const dispatch = useDispatch()

  const { evaluation, availableLimit } = props

  const [evaluationStoreName, setEvaluationStoreName] = useState<string | undefined>(undefined)

  const evaluationMaxAmount = evaluation.policy?.maxAmount ?? 0

  const totalAmount = availableLimit ? calculateTxCreditTotalAmount(evaluationMaxAmount, availableLimit) : undefined

  const evaluationExpirationDateFormatted = formatDate(evaluation.expirationTimestamp, DateFormat.DAY_MONTH_BR_DATE_FORMAT, TimeZone.MANAUS)

  const subtitle =
    evaluation.result === EvaluationResultEnum.DENIED
      ? 'Cadastro do cliente foi realizado! Ele já pode aproveitar o seu crédito para comprar.'
      : `Cadastro do cliente foi realizado e a Super Compra está ativa para esta loja até ${evaluationExpirationDateFormatted}.`

  const handleGoBackToStart = () => {
    dispatch(BudgetReducer.actions.resetRequest())
    navigateToPath(PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE)
  }

  useEffect(() => {
    const fetchStore = async () => {
      const evaluationStore = evaluation.parameters.storeId
      if (evaluationStore) {
        const storeData = await bffParceirosApiService.retailers.fetchStoreById(evaluationStore)
        setEvaluationStoreName(storeData.name)
      }
    }

    analyticsService.sendEvent(evaluation.result === EvaluationResultEnum.DENIED ? AnalyticsEvents.sp_denied_application_success : AnalyticsEvents.sp_approved_application_success)
    fetchStore()
  }, [])

  return (
    <TransactionalCreditResultGenericScreen
      style={{
        mainContainerPadding: '24px 24px 24px 24px',
      }}
      icon={<ApprovedCheckSvg />}
      title={'Cliente cadastrado'}
      subtitle={subtitle}
      limits={{ title: 'Valor total disponível para comprar', totalAmount, recurrenceAvailableAmount: availableLimit, evaluationMaxAmount }}
      renderProgressBar={!!totalAmount}
      alertBox={{
        evaluationResult: evaluation.result,
        evaluationStoreName,
        evaluationExpirationDate: evaluationExpirationDateFormatted,
      }}
      onCloseButtonClick={handleGoBackToStart}
      footerButton={{ text: 'Voltar para o Início', textColor: green.dark2, backgroundColor: white.primary, border: `1px solid ${green.dark2}`, onClick: handleGoBackToStart }}
      shouldRenderCloseButton={false}
    />
  )
}

export default ApplicationWithTransactionalCreditEvaluation
