import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { GetBiometryLivenessStatusResponseDTO, SubmitBiometryLivenessRequestDTO } from './dto/BiometryLiveness'

export class BiometryService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async submitBiometryLiveness(requestPayload: SubmitBiometryLivenessRequestDTO): Promise<void> {
    await this.axiosInstance.post<void>(`/biometry/application/process`, requestPayload)
  }

  async getBiometryLivenessStatus(borrowerId: string): Promise<GetBiometryLivenessStatusResponseDTO> {
    const response = await this.axiosInstance.get<GetBiometryLivenessStatusResponseDTO>(`/biometry/application/process/latest`, { params: { borrowerId } })
    return response.data
  }
}
