import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { history } from '../../../App'
import LocalStorageWrapper, { LocalStorageItem } from '../../../common/assets/utils/LocalStorageWrapper'
import { bffParceirosApiService } from '../../../services/bff'
import ConfigurationsSliceReducer, { Configurations, IFetchConfigurationsPayload } from './configurations.reducer'
function* fetchConfigurations(action: PayloadAction<IFetchConfigurationsPayload>) {
  try {
    const store = bffParceirosApiService.coordinator.getStore()
    const storeId = store?.id ?? ''
    const retailerId = store?.retailerId ?? ''
    const configurations: Configurations = yield bffParceirosApiService.configurations.fetchConfigurations(storeId, retailerId)

    LocalStorageWrapper.setItem(LocalStorageItem.CONFIGURATIONS, configurations)

    yield put(ConfigurationsSliceReducer.actions.fetchConfigurationsSuccess(configurations))
  } catch (error) {
    yield put(ConfigurationsSliceReducer.actions.fetchConfigurationsError(error))
  }
}

function* handleDeactivatedStore(action: PayloadAction<undefined>) {
  history.push('/configuracao')
}

const configurationsSaga = [
  takeLatest(ConfigurationsSliceReducer.actions.fetchConfigurations, fetchConfigurations),
  takeLatest(ConfigurationsSliceReducer.actions.handleDeactivatedStore, handleDeactivatedStore),
]

export default configurationsSaga
