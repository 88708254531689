import { CameraOpener, SelfieCameraTypes, UnicoCheckBuilder, UnicoConfig, UnicoThemeBuilder } from 'unico-webframe'
import { getConfig } from '../../config/config'

class UnicoSDKService {
  camera: Promise<CameraOpener> | undefined
  constructor() {
    const config = getConfig().biometryConfig.unicoLivenessConfig

    const unicoConfig = new UnicoConfig()
      .setProjectNumber(config.projectNumber)
      .setProjectId(config.projectId)
      .setMobileSdkAppId(config.mobileSDKAppId)
      .setHostname(config.hostname)
      .setHostInfo(config.hostInfo)
      .setHostKey(config.hostKey)

    const unicoCameraBuilder = new UnicoCheckBuilder()

    const unicoTheme = new UnicoThemeBuilder()
      .setColorSilhouetteSuccess('#11b048')
      .setColorSilhouetteError('#D50000')
      .setColorSilhouetteNeutral('#fcfcfc')
      .setBackgroundColor('#dff1f5')
      .setColorText('#11b048')
      .setBackgroundColorComponents('#11b048')
      .setColorTextComponents('#dff1f5')
      .setBackgroundColorButtons('#11b048')
      .setColorTextButtons('#dff1f5')
      .setBackgroundColorBoxMessage('#fff')
      .setColorTextBoxMessage('#000')
      .setHtmlPopupLoading(
        `<div style="position: absolute; top: 45%; right: 50%; transform:
  translate(50%, -50%); z-index: 10; text-align: center; height: 100%;">Carregando...</div>`
      )
      .build()

    unicoCameraBuilder.setModelsPath(config.modelsPath).setResourceDirectory(config.resourcesPath)
    unicoCameraBuilder.setTheme(unicoTheme)
    const unicoCamera = unicoCameraBuilder.build()

    this.camera = unicoCamera.prepareSelfieCamera(unicoConfig, SelfieCameraTypes.SMART)
  }
}

export default UnicoSDKService
