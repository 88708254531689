import React, { useEffect, useState } from 'react'
import { ReactComponent as ApprovedCheckIcon } from '../../../../common/assets/images/approved-check-circle.svg'
import { DateFormat, formatDate, TimeZone } from '../../../../common/FormatDate'
import { calculateTxCreditTotalAmount } from '../../../../common/TransactionalCreditHelper'
import analyticsService from '../../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../../services/analytics/events'
import { bffParceirosApiService } from '../../../../services/bff'
import { AggregatedTransactionalCreditEvaluation } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { green, white } from '../../../../ui/utils/_colors'
import TransactionalCreditResultGenericScreen from '../TransactionalCreditResultGenericScreen.tsx'

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  hasCompletedApplication: boolean
  handleGoBackToStart?: () => void
  handleGoToApplication?: () => void
}

export const TransactionalCreditApprovedResult: React.FC<Props> = (props: Props) => {
  const { evaluationResult, hasCompletedApplication, handleGoBackToStart, handleGoToApplication } = props

  const [evaluationStoreName, setEvaluationStoreName] = useState<string | undefined>(undefined)

  const borrowerAvailableLimit = evaluationResult.limit?.availableLimit ?? 0
  const evaluationMaxAmount = evaluationResult.evaluation.policy!!.maxAmount ?? 0

  const totalAmount = calculateTxCreditTotalAmount(evaluationMaxAmount, borrowerAvailableLimit)

  const evaluationExpirationDateFormatted = formatDate(evaluationResult.evaluation.expirationTimestamp, DateFormat.DAY_MONTH_BR_DATE_FORMAT, TimeZone.MANAUS)

  const subtitle = hasCompletedApplication
    ? `Cliente já pode aproveitar o limite extra aprovado nesta loja no caixa até ${evaluationExpirationDateFormatted}.`
    : `Realize o cadastro do cliente e ele já poderá aproveitar o limite extra aprovado nesta loja até o dia ${evaluationExpirationDateFormatted}.`

  const footerButton = hasCompletedApplication
    ? { text: 'Voltar para o Início', textColor: green.dark2, backgroundColor: white.primary, border: `1px solid ${green.dark2}`, onClick: handleGoBackToStart }
    : { text: 'Cadastrar cliente', textColor: white.primary, backgroundColor: green.dark2, onClick: handleGoToApplication }

  useEffect(() => {
    const fetchStore = async () => {
      const evaluationStore = evaluationResult.evaluation.parameters.storeId
      if (evaluationStore) {
        const storeData = await bffParceirosApiService.retailers.fetchStoreById(evaluationStore)
        setEvaluationStoreName(storeData.name)
      }
    }

    fetchStore()

    return !hasCompletedApplication ? analyticsService.sendEvent(AnalyticsEvents.sp_approved_application_approved) : analyticsService.sendEvent(AnalyticsEvents.sp_approved)
  }, [])

  return (
    <TransactionalCreditResultGenericScreen
      style={{
        mainContainerPadding: '24px 24px 24px 24px',
      }}
      shouldRenderCloseButton
      icon={<ApprovedCheckIcon />}
      title={'Super Compra aprovada'}
      subtitle={subtitle}
      limits={{ title: 'Valor total disponível para comprar', totalAmount, recurrenceAvailableAmount: borrowerAvailableLimit, evaluationMaxAmount }}
      renderProgressBar
      installmentsSimulation={evaluationResult?.simulation}
      alertBox={{
        evaluationResult: evaluationResult.evaluation.result,
        evaluationStoreName,
        evaluationExpirationDate: evaluationExpirationDateFormatted,
      }}
      onCloseButtonClick={handleGoBackToStart}
      footerButton={footerButton}
    />
  )
}
