import React from 'react'
import { ReactComponent as AlertIcon } from '../../../../common/assets/images/alert-icon.svg'
import { ReactComponent as ApprovedCheckDiamondIcon } from '../../../../common/assets/images/approved-check-circle-diamond.svg'
import TransactionalCreditHelper from '../../../../pages/transactional-credit/TransactionalCreditHelper'
import analyticsService from '../../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../../services/analytics/events'
import { AggregatedTransactionalCreditEvaluation, DeniedCreditStatus } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { green, white } from '../../../../ui/utils/_colors'
import TransactionalCreditResultGenericScreen from '../TransactionalCreditResultGenericScreen.tsx'

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  hasCompletedApplication: boolean
  handleGoBackToStart?: () => void
  handleGoToApplication?: () => void
}

const defineUIData = (deniedCreditStatus: DeniedCreditStatus) => {
  switch (deniedCreditStatus) {
    case DeniedCreditStatus.PRE_APPLICATION_APPROVED_AND_EVALUATION_DENIED:
      analyticsService.sendEvent(AnalyticsEvents.sp_denied_application_approved)

      return {
        icon: <ApprovedCheckDiamondIcon />,
        title: 'Cliente com limite disponível',
        subtitle: 'Cadastre o cliente para ele utilizar o limite pré-aprovado disponível. Lembre-se de solicitar que o cliente baixe o app Ume.',
        limit: {
          title: 'Limite pré-aprovado',
          shouldRenderProgressBar: false,
        },
      }
    case DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_ABOVE_THRESHOLD:
      analyticsService.sendEvent(AnalyticsEvents.sp_denied_ume_limit_above_10)

      return {
        icon: <ApprovedCheckDiamondIcon />,
        title: 'Cliente com limite disponível',
        subtitle: 'Cliente já cadastrado e com limite recorrente disponível para comprar na sua loja.',
        limit: {
          title: 'Valor total disponível para comprar',
          shouldRenderProgressBar: true,
        },
      }
    case DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_BELOW_THRESHOLD:
      analyticsService.sendEvent(AnalyticsEvents.sp_denied_ume_limit_below_10)

      return {
        icon: <AlertIcon />,
        title: 'Super Compra não foi aprovada',
        subtitle: 'Cliente já é cadastrado, mas está com limite muito baixo e não obteve limite extra do Super Compra. Oriente-o a pagar suas parcelas em aberto e voltar a comprar.',
        limit: {
          title: 'Valor total disponível para comprar',
          shouldRenderProgressBar: false,
        },
      }
  }
}

export const TransactionalCreditDeniedResult: React.FC<Props> = (props: Props) => {
  const { evaluationResult, hasCompletedApplication, handleGoBackToStart, handleGoToApplication } = props

  const borrowerAvailableLimit = evaluationResult.limit?.availableLimit ?? 0

  const deniedStatus = TransactionalCreditHelper.defineDeniedStatus(hasCompletedApplication, borrowerAvailableLimit)
  const deniedData = defineUIData(deniedStatus)

  const footerButton = hasCompletedApplication
    ? { text: 'Voltar para o Início', textColor: green.dark2, backgroundColor: white.primary, border: `1px solid ${green.dark2}`, onClick: handleGoBackToStart }
    : { text: 'Cadastrar cliente', textColor: white.primary, backgroundColor: green.dark2, onClick: handleGoToApplication }

  return (
    <TransactionalCreditResultGenericScreen
      style={{
        mainContainerPadding: '24px 24px 220px 24px',
      }}
      shouldRenderCloseButton
      icon={deniedData.icon}
      title={deniedData.title}
      subtitle={deniedData.subtitle}
      limits={{ title: deniedData.limit.title, totalAmount: borrowerAvailableLimit, recurrenceAvailableAmount: borrowerAvailableLimit }}
      renderProgressBar={deniedData.limit.shouldRenderProgressBar}
      onCloseButtonClick={handleGoBackToStart}
      footerButton={footerButton}
    />
  )
}
