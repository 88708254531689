import { PayloadAction } from '@reduxjs/toolkit'
import { call, delay, fork, put, race, take, takeLatest } from 'redux-saga/effects'
import { bffParceirosApiService } from '../../../services/bff'
import { BiometryLivenessStatus, GetBiometryLivenessStatusResponseDTO } from '../../../services/bff/biometry/dto/BiometryLiveness'
import { selectState } from '../selectors'
import { BiometryLivenessReducer, SubmitBiometryLivenessAction } from './biometry-liveness.reducer'

function* submitBiometryLiveness(action: PayloadAction<SubmitBiometryLivenessAction>) {
  try {
    const borrowerId: string = yield selectState(state => state.application.borrowerId)
    yield bffParceirosApiService.biometry.submitBiometryLiveness({ biometryObject: action.payload.biometryObject, borrowerId })
    yield put(BiometryLivenessReducer.actions.submitBiometryLivenessSuccess())
  } catch (error) {
    yield put(BiometryLivenessReducer.actions.submitBiometryLivenessError())
  }
}

function* poolBiometryLivenessStatus() {
  const borrowerId: string = yield selectState(state => state.application.borrowerId)

  while (true) {
    const response: GetBiometryLivenessStatusResponseDTO = yield bffParceirosApiService.biometry.getBiometryLivenessStatus(borrowerId)
    if (response.status === BiometryLivenessStatus.PENDING) {
      yield delay(1000)
      continue
    }

    yield put(BiometryLivenessReducer.actions.endBiometryLivenessStatusPooling({ status: response.status }))

    yield delay(1000)
  }
}

function* poolBiometryLivenessStatusWatcher() {
  while (true) {
    yield take(BiometryLivenessReducer.actions.submitBiometryLivenessSuccess)
    yield race([call(poolBiometryLivenessStatus), take(BiometryLivenessReducer.actions.endBiometryLivenessStatusPooling)])
  }
}

const biometryLivenessSaga = [takeLatest(BiometryLivenessReducer.actions.submitBiometryLiveness, submitBiometryLiveness), fork(poolBiometryLivenessStatusWatcher)]

export default biometryLivenessSaga
