import React from 'react'
import styled from 'styled-components'
import { gray } from '../../ui/utils/_colors'

interface ProgressBarProps {
  primaryBar?: IProgressBar
  secondaryBar?: IProgressBar
}

interface IProgressBar {
  value: number
  title?: string
  color: string
}

const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const { primaryBar, secondaryBar } = props

  const totalValue = (primaryBar?.value ?? 0) + (secondaryBar?.value ?? 0)

  const calculateWidth = (barValue: number | undefined, minimalWidth: number, fallbackWidth: string) =>
    barValue && barValue > 0 ? `${Math.max((barValue / totalValue) * 100, minimalWidth)}%` : fallbackWidth

  const primaryProgressWidth = calculateWidth(primaryBar?.value, 30, secondaryBar ? '0%' : '100%')
  const secondaryProgressWidth = calculateWidth(secondaryBar?.value, 30, primaryBar ? '0%' : '100%')

  return (
    <ProgressBarContainer>
      {[primaryBar, secondaryBar].map(
        (bar, index) =>
          bar && (
            <BarContainer key={index} width={index === 0 ? primaryProgressWidth : secondaryProgressWidth}>
              {bar.title && <Title>{bar.title}</Title>}
              <Progress max={bar.value} color={bar.color} />
            </BarContainer>
          )
      )}
    </ProgressBarContainer>
  )
}

export default ProgressBar

interface BarStyleProps {
  color: string
  width: string
}

interface TitleStyleProps {
  marginLeft: string
}

const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 100px;
`

const BarContainer = styled.div<BarStyleProps>`
  display: flex;
  flex-direction: column;
  width: ${(props: BarStyleProps) => props.width};
  overflow: hidden;
`

const Progress = styled.div<BarStyleProps>`
  height: 4px;
  margin-top: 4px;
  background-color: ${(props: BarStyleProps) => props.color};
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
`

const Title = styled.div<TitleStyleProps>`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${gray.scale700};
  margin-right: ${(props: TitleStyleProps) => props.marginLeft};
`
