import { Callout, Intent } from '@blueprintjs/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Loading from '../atoms/Loading/Loading'
import { isServiceCentralAllowed, isSmartphoneSaleAllowed, isTransactionalCreditAllowed } from '../common/AccessHelper'
import FeatureFlags from '../common/assets/utils/featureFlags'
import LocalStorageWrapper, { LocalStorageItem } from '../common/assets/utils/LocalStorageWrapper'
import { Role } from '../common/constants'
import { isReportsPageActive } from '../config/config'
import { NavItem, NavItemEnum, PAGES_ALLOWED } from '../molecules/header/components/Header/Header'
import LoggedUserInfoCard from '../molecules/LoggedUserInfoCard/LoggedUserInfoCard'
import BannerWaning from '../pages/Budget/BannerWarning'
import { HomeCard } from '../pages/Home/HomeCard'
import ConfigurationsSliceReducer from '../redux/reducers/configurations/configurations.reducer'
import { useTypedSelector } from '../redux/reducers/selectors'
import analyticsService from '../services/analytics/analytics.service'
import { AnalyticsEvents } from '../services/analytics/events'
import { bffParceirosApiService } from '../services/bff'

export interface HomeProps {
  history?: any
  user?: any
}

const Home = (props: HomeProps) => {
  const dispatch = useDispatch()
  const { loadingConfigurations, configurations, hasFetchedConfigurations } = useTypedSelector(state => ({
    loadingConfigurations: state.configurations.isLoadingConfigurations,
    configurations: state.configurations.configurations,
    hasFetchedConfigurations: state.configurations.hasFetchedConfigurations,
  }))

  React.useEffect(() => {
    if (!hasFetchedConfigurations) {
      loadConfigurations()
    }
  }, [])

  const loadConfigurations = () => {
    dispatch(ConfigurationsSliceReducer.actions.fetchConfigurations({}))
  }

  const handleHomeCardClick = (item: NavItem) => {
    if (item.id && item.id === NavItemEnum.LOGOUT) {
      bffParceirosApiService.auth.logout()
    }

    if (item.id && item.id === NavItemEnum.BUDGET) {
      analyticsService.sendEvent(AnalyticsEvents.budget_button)
    }

    props.history.push(item.path)
  }

  const isAllowedToSeePage = (pageData: NavItem, userRole: Role) => {
    return pageData.authorizedRoles.includes(userRole)
  }

  if (loadingConfigurations)
    return (
      <PageContainer>
        <Loading></Loading>
      </PageContainer>
    )

  const userRole = LocalStorageWrapper.getItem(LocalStorageItem.OPERATOR).role

  const navItems = PAGES_ALLOWED({
    isReportPageActive: isReportsPageActive,
    transactionalCredit: { isAllowed: isTransactionalCreditAllowed(configurations), shouldHighlightIcon: true },
    operatorServiceCenter: { isAllowed: isServiceCentralAllowed(configurations), shouldHighlightIcon: true },
    smartphoneSale: { isAllowed: isSmartphoneSaleAllowed(configurations), shouldHighlightIcon: true },
  })

  return (
    <PageContainer>
      <LoggedUserInfoCard />

      <Callout
        style={{ backgroundColor: '#F0FDF2', marginTop: '2vh' }}
        intent={Intent.NONE}
        icon={null}
        title="Seja bem-vindo!"
      ></Callout>

      {FeatureFlags.isBudgetBlackCampaignActive() ? <BannerWaning /> : undefined}
      {/* {FeatureFlags.isBudgetBlackCampaignActive() ? <BudgetBanner /> : undefined} */}

      <Container>
        {navItems.map((item, idx) =>
          isAllowedToSeePage(item, userRole) ? (
            <HomeCard onClick={() => handleHomeCardClick(item)} subtitle={item.label} icon={item.icon} to={item.path} />
          ) : (
            undefined
          )
        )}
      </Container>
    </PageContainer>
  )
}

export default Home

const PageContainer = styled.div`
  margin: 3vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`
