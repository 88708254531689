import PersonIcon from '@material-ui/icons/Person'
import { IStoreResponse } from '@scudraservicos/coordinator-client/dist/src/services/stores/interfaces/Stores.res.interface'
import React, { useCallback, useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../../App'
import LoadingSpinner from '../../../atoms/Loading/LoadingSpinner'
import { validate_cpf } from '../../../common/Validators'
import * as UMEColors from '../../../legacy-lib/design-kit-ui/utils/_colors'
import NumberField from '../../../molecules/Application/NumberField'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../../services/bff'
import DocumentUtils from '../../../utils/document.utils'
import ApplicationLayout from '../ApplicationLayout'

interface Props {
  cpf: string
}

// TODO - Type this
const ApplicationCpfStep = (props: Props) => {
  const dispatch = useDispatch()

  const isLoadingPreApplication = useTypedSelector(state => state.application.isLoadingPreApplication)

  const [formCpf, setFormCpf] = useState<string>(props.cpf ?? '')
  const [localStorageStore, setLocalStorageStore] = useState<IStoreResponse>()

  const loadConfiguredStore = useCallback(async () => {
    const store = await bffParceirosApiService.coordinator.getStore()
    setLocalStorageStore(store)
  }, [])

  useEffect(() => {
    loadConfiguredStore()
  }, [localStorageStore])

  const handleEnter = () => {
    if (!localStorageStore) {
      return toaster.showErrorToast(`Configure uma loja antes.`)
    }

    if (!validate_cpf(formCpf)) {
      return toaster.showErrorToast(`Preencha um CPF válido`)
    }

    dispatch(ApplicationSliceReducer.actions.onFormValueChange({ formField: 'cpf', formValue: formCpf }))
    dispatch(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: formCpf }))
  }

  const handleCpfChange = (values: NumberFormatValues) => {
    setFormCpf(values.value)
  }

  const isCpfValidated = formCpf.length === DocumentUtils.CPF_SIZE_WITHOUT_DIGITS
  const isCpfError = formCpf.length > 0 && !isCpfValidated

  const allowedDocuments = [
    'RG',
    'RG digital',
    'CNH',
    'Nova CNH',
    'CNH digital',
    'Passaporte',
    'Carteira de trabalho',
    'Identidade Militar',
    'RG Estrangeiro',
  ]

  if (isLoadingPreApplication) {
    return <LoadingSpinner text="Avaliando perfil do cliente..." />
  }

  return (
    <>
      {!localStorageStore ? <WarningHeader>Loja não configurada</WarningHeader> : <></>}
      <ApplicationLayout
        title="Cadastro"
        subtitle="Antes de iniciar um novo processo de cadastro, verificar se o cliente possui em mãos:"
        disableFooter
      >
        <Text>
          - Telefone celular com sinal
          <br />- Algum dos documentos:
          <ul>
            {allowedDocuments.map((doc, index) => (
              <li key={index}>{doc}</li>
            ))}
          </ul>
        </Text>

        <BodyContainer>
          <NumberField
            autoFocus
            Icon={PersonIcon}
            label="CPF"
            value={formCpf}
            type="tel"
            format="###.###.###-##"
            mask="_"
            placeholder="000.000.000-00"
            onValueChange={handleCpfChange}
            onEnterPressed={handleEnter}
            isValidated={isCpfValidated}
            error={isCpfError}
          />

          <ConfirmButton disabled={!isCpfValidated || !localStorageStore} onClick={handleEnter}>
            Confirmar
          </ConfirmButton>
        </BodyContainer>
      </ApplicationLayout>
    </>
  )
}

export default ApplicationCpfStep

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  margin-top: 2vh;

  color: #000000;
`

const ConfirmButton = styled.button`
  margin-top: 2vh;

  // BUTTON
  background: ${(props: any) => (props && props.disabled ? '#a9e1bc' : '#10A343')};
  border-radius: 10px;
  border: 0px;
  padding: 12px;

  // TEXT
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
`

const BodyContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  margin-top: 10vh;
`

const WarningHeader = styled.div`
  width: 100%;
  background-color: ${UMEColors.orange.amber};
  color: #fff;
  padding: 5px;
  font-family: Roboto;
  letter-spacing: 0.3px;
`
