import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ApprovedCircle } from '../../common/assets/images/approved-check-circle.svg'
import ApplicationLayout from '../../organisms/Application/ApplicationLayout'
import { BiometryLivenessStatus } from '../../services/bff/biometry/dto/BiometryLiveness'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import BiometryLivenessErrorPage from './BiometryLivenessErrorPage'

interface Props {
  biometryLivenessStatus: BiometryLivenessStatus
  onTryAgain: () => void
}

const BiometryLivenessResultPage = ({ biometryLivenessStatus, onTryAgain }: Props) => {
  if (biometryLivenessStatus === BiometryLivenessStatus.COMPLETED) {
    return (
      <ApplicationLayout title={'Biometria concluída'} subtitle={''} isNextButtonEnabled={true}>
        <Container>
          <ApprovedCircle />
          <SuccessMessage> Biometria coletada com sucesso! </SuccessMessage>
        </Container>
      </ApplicationLayout>
    )
  }

  if (biometryLivenessStatus === BiometryLivenessStatus.MUST_RETRY) {
    return <BiometryLivenessErrorPage onTryAgain={onTryAgain} />
  }

  return <LoadingSpinner text={'processando...'} />
}

export default BiometryLivenessResultPage

const Container = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SuccessMessage = styled.span`
  margin-top: 3vh;
`
