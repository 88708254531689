import React from 'react'
import { Redirect, RouteComponentProps, RouteProps } from 'react-router-dom'
import { OperatorCenterHelper } from '../pages/Operators/OperatorCenterHelper'
import LocalStorageWrapper, { LocalStorageItem } from './assets/utils/LocalStorageWrapper'

interface RedirectWrapperProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>
  currentPath: string
}

const RedirectWrapper: React.FC<RedirectWrapperProps> = ({ component: Component, currentPath, ...props }) => {
  const localStorageConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.CONFIGURATIONS)
  const localStorageOperator = LocalStorageWrapper.getItem(LocalStorageItem.OPERATOR)

  const operatorSalesRoleHomePath = OperatorCenterHelper.getOperatorSalesRoleHomePath(localStorageConfigurations, localStorageOperator.role)

  if (operatorSalesRoleHomePath && operatorSalesRoleHomePath !== currentPath) {
    return <Redirect to={operatorSalesRoleHomePath} />
  }

  return <Component {...(props as RouteComponentProps)} />
}

export default RedirectWrapper
