import React from 'react'
import styled from 'styled-components'
import { Loading } from '../../../ui/Loading'
import { gray, green, white } from '../../../ui/utils/_colors'

export interface IAvailableProductButtonProps {
  title: string
  icon: JSX.Element
  onButtonPress: () => void
  isLoading: boolean
  shouldRender: boolean
}

const AvailableProductButton: React.FC<IAvailableProductButtonProps> = props => {
  const { title, icon, onButtonPress, isLoading } = props

  return (
    <MainContainer>
      <ButtonContainer>
        {isLoading ? (
          <LoadingContainer>
            <Loading color={green.primary600} height={40} width={40} />
          </LoadingContainer>
        ) : (
          <Button onClick={onButtonPress}>
            <ProductIcon>{icon}</ProductIcon>
            <ProductTitle>{title}</ProductTitle>
          </Button>
        )}
      </ButtonContainer>
    </MainContainer>
  )
}

export default AvailableProductButton

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 140px;
  padding: 24px 0px;
  border-radius: 16px;
  align-items: center;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  margin: 10px 0px;
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 140px;
  height: 142px;
  padding: 16px;

  border-radius: 8px;
  border: 1px solid ${gray.scale300};
  background: ${white.primary};
  cursor: pointer;
`

const ProductIcon = styled.span`
  > div > svg {
    width: 36px;
    height: 36px;
`

const ProductTitle = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  color: ${gray.scale700};
  text-align: center;
  line-height: 18px;
  padding-top: 8px;
`
